import React, { useState } from 'react';
import {
	Button,
	Paper,
	Box,
	Typography,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
} from '@mui/material';

import { useSnackbar } from '../../context/SnackBarContext';

import axios from 'axios';
import { direccion } from '../../utils/localhost';
import { crearUbicacion } from '../../services/ubicacion.service';
const token = JSON.parse(localStorage.getItem('colaborador'));

export default function Ubicaciones() {
	const [selectedCity, setSelectedCity] = useState('');
	const [ubicationName, setUbicationName] = useState('');

	const { showSnackbar } = useSnackbar();

	const handleChange = (e) => {
		setSelectedCity(e.value);
	};

	const ciudades = [
		{
			id_ciudad: 1,
			nombre_ciudad: 'La Paz',
		},
		{
			id_ciudad: 2,
			nombre_ciudad: 'Cochabamba',
		},
		{
			id_ciudad: 3,
			nombre_ciudad: 'Santa Cruz',
		},
	];

	const registerNewUbication = async () => {
		const data = {
			nombre_ubicacion: ubicationName,
			id_ciudad: selectedCity,
		};

		await axios
			.post(`${direccion}ubication/create`, data, {
				headers: {
					authorization: 'Bearer ' + token.token,
				},
			})
			.then(({ data }) => {
				setSelectedCity('');
				setUbicationName('');
				showSnackbar({
					message: data.message,
					severity: data.type,
				});
			})
			.catch((error) => {
				const errorData = error.response.data;
				showSnackbar({
					message: errorData.message,
					severity: errorData.type,
				});
			});
	};

	return (
		<Grid item>
			<Paper elevation={3} sx={{ padding: 2 }}>
				<Typography align='center' variant='h6'>
					Registro de transportadora
				</Typography>

				<Box display={'flex'} justifyContent={'space-around'}>
					<TextField
						onChange={(e) => setUbicationName(e.target.value)}
						label='Nombre de la transportadora'
						value={ubicationName}
					/>

					<FormControl style={{ width: '300px' }}>
						<InputLabel id='city'>Ciudad</InputLabel>
						<Select
							labelId='city'
							id='city'
							value={selectedCity}
							label='Ciudad'
							name='id_ciudad'
							onChange={(e) => {
								handleChange(e.target);
							}}
						>
							{ciudades.map((ciudad, i) => {
								return (
									<MenuItem key={i} value={ciudad.id_ciudad}>
										{ciudad.nombre_ciudad}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
					<Button onClick={registerNewUbication} variant='contained'>
						Registrar nueva transportadora
					</Button>
				</Box>
			</Paper>
		</Grid>
	);
}
