import React, { useState, useRef, useContext } from 'react';
import { useSnackbar } from '../../../context/SnackBarContext';

import {
	Typography,
	TextField,
	Input,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Grid,
	Autocomplete,
	Box,
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';

import { styled } from '@mui/material/styles';

import {
	findMaestro,
	findVariantes,
} from '../../../services/productos.service';

import FormServicio from '../servicio/Servicio.component';

import { ExpandMore } from '@mui/icons-material';

import { UserContext } from './../../../context/UserContext';
import DialogEspere from './../../../components/DialogEspere.component';

const AccordionDetailsCustom = styled(AccordionDetails)`
	display: flex;
	justify-content: center;
`;

export default function RegistrarProducto({ datosOtg, setDatosOtg }) {
	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const [productosMaestros, setProductosMaestros] = useState([]);

	const [variacion, setVariacion] = useState({});

	const [productosVariantes, setProductosVariantes] = useState([]);

	const datosModal = useRef({
		nombre_producto: '',
		responsable: '',
		tipo_servicio: '',
	});

	const [mostrarEspere, setMostrarEspere] = useState(false);
	const [dropp, setDrop] = useState(false);

	const getMaestros = (maestro) => {
		findMaestro(maestro)
			.then((response) => {
				setMostrarEspere(false);
				setProductosMaestros(response.data);
			})
			.catch((err) => {
				setMostrarEspere(false);

				handleClickSnackbar('Error al momento de buscar el producto', 'error');
			});
	};

	const getVariantes = (cod_referencia) => {
		setMostrarEspere(true);
		findVariantes(cod_referencia)
			.then((response) => {
				const _productosVariantes = [];

				response.map((variante) => {
					const productoVariante = {
						id: variante.id_variacion,
						nombre_producto: variante.nombre_producto,
						talla: variante.Talla.nombre_talla,
						color: variante.Color.nombre_color,
					};

					_productosVariantes.push(productoVariante);
					return _productosVariantes;
				});
				setProductosVariantes(_productosVariantes);
				setMostrarEspere(false);
			})
			.catch(() => {
				setMostrarEspere(false);

				handleClickSnackbar('El producto no ha sido encontrado.', 'error');
			});
	};

	const datosSession = useContext(UserContext);

	const columns = [
		{
			field: 'nombre_producto',
			headerName: 'Producto',
			width: '300',
		},
		{
			field: 'talla',
			headerName: 'Talla',
		},
		{
			field: 'color',
			headerName: 'Color',
		},
		{ field: 'id', headerName: 'ID' },
	];

	return (
		<>
			<DialogEspere open={mostrarEspere} />
			<Autocomplete
				clearOnEscape
				noOptionsText='No encontrado'
				options={productosMaestros}
				getOptionLabel={(option) =>
					`${option.id_producto_maestro} ${option.referencia} ${option.cod_referencia}`
				}
				renderInput={(params) => (
					<TextField
						{...params}
						label='Codigo de referencia o nombre del producto'
					/>
				)}
				onInputChange={(e, maestro) => {
					if (maestro.length > 3) {
						getMaestros(maestro);
					}
				}}
				onChange={(e, maestro) => {
					if (maestro) {
						getVariantes(maestro.cod_referencia);
					}
				}}
			/>

			<Box sx={{ height: 400, width: '100%' }}>
				<DataGrid
					columns={columns}
					rows={productosVariantes}
					onRowClick={(value) => {
						setVariacion({
							nombre_variacion: value.row.nombre_producto,
							unidad_negocio: 'response.cod_unidad_negocio',
							color: value.row.color,
							talla: value.row.talla,
							ean: 'response.ean',
							linea_producto: 'response.linea_producto',
							temporada: 'response.temporada',
						});

						setDrop(true);
						setDatosOtg({ ...datosOtg, id_variacion: value.id });
						datosModal.current.nombre_producto = value.row.nombre_producto;
					}}
				/>
			</Box>
			{/* INFORMACION DEL PRODUCTO */}
			<Accordion expanded={dropp}>
				<Grid container justifyContent='space-around' alignItems='center'>
					<Typography variant='subtitle1'>Producto seleccionado</Typography>

					<AccordionSummary expandIcon={<ExpandMore />}></AccordionSummary>
				</Grid>

				<AccordionDetailsCustom>
					<Grid item md={10}>
						<Grid container direction='row' justifyContent='space-between'>
							<Typography variant='caption'>Nombre:</Typography>
							<Typography variant='caption'>
								{variacion.nombre_variacion}
							</Typography>
						</Grid>

						<Grid container direction='row' justifyContent='space-between'>
							<Typography variant='caption'>Color:</Typography>
							<Typography variant='caption'>{variacion.color}</Typography>
						</Grid>
						<Grid container direction='row' justifyContent='space-between'>
							<Typography variant='caption'>Talla:</Typography>
							<Typography variant='caption'>{variacion.talla}</Typography>
						</Grid>
					</Grid>
				</AccordionDetailsCustom>
			</Accordion>

			{/* INFORMACION DE LA OTG */}
			<Accordion expanded={dropp}>
				<Grid container justifyContent='space-around' alignItems='center'>
					<Typography variant='subtitle1'>Información de la OTG</Typography>

					<AccordionSummary expandIcon={<ExpandMore />}></AccordionSummary>
				</Grid>

				<AccordionDetailsCustom>
					<Grid item md={10}>
						<Grid container justifyContent='space-between' sx={{ my: 2 }}>
							<Typography variant='caption'>
								Fecha de compra del producto:
							</Typography>

							<Input
								type='date'
								onChange={(e) => {
									setDatosOtg({
										...datosOtg,
										fecha_compra: e.target.value,
									});
								}}
								value={datosOtg.fecha_compra}
							/>
						</Grid>

						<TextField
							fullWidth
							label='Observaciones'
							name='observaciones'
							multiline
							placeholder='Ej. El producto tiene una descostura interna.'
							value={datosOtg.observaciones}
							onChange={(e) => {
								setDatosOtg({
									...datosOtg,
									observaciones: e.target.value,
								});
							}}
						/>
					</Grid>
				</AccordionDetailsCustom>
			</Accordion>

			{/* INFORMACION DEL SERVICIO */}
			<FormServicio
				dropAcordion={dropp}
				getServiciosFromComponent={(servicios) => {
					setDatosOtg({ ...datosOtg, servicios });
				}}
			></FormServicio>

			{/* INFORMACION DE LA TIENDA */}
			<Accordion expanded={dropp}>
				<Grid container justifyContent='space-around' alignItems='center'>
					<Typography variant='subtitle1'>Información de la tienda</Typography>

					<AccordionSummary expandIcon={<ExpandMore />}></AccordionSummary>
				</Grid>

				<AccordionDetailsCustom>
					<Grid item md={10}>
						<Grid container justifyContent='space-around' alignItems={'center'}>
							<Typography>Usuario: {datosSession.usuario}</Typography>

							<TextField
								label='Responsable'
								name='responsable'
								value={datosOtg.responsable}
								onChange={(e) => {
									setDatosOtg({ ...datosOtg, responsable: e.target.value });
								}}
							/>
						</Grid>
					</Grid>
				</AccordionDetailsCustom>
			</Accordion>
		</>
	);
}
