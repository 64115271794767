import React from 'react';

import {
	Dialog,
	DialogContent,
	DialogTitle,
	CircularProgress,
} from '@mui/material';

export default function DialogEspere(props) {
	const { open } = props;

	return (
		<Dialog open={open}>
			<DialogTitle>Espere por favor</DialogTitle>
			<DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</DialogContent>
		</Dialog>
	);
}
