import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

export const findEstados = () => {
	return axios
		.get(`${API_URL}find_estados`, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};
