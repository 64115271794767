import React, { useEffect, useState } from 'react';
import { useSnackbar } from '../../context/SnackBarContext';
import {
    BrowserRouter as Router,
    Link as RouterLink,
    Route,
    Switch,
    useHistory,
} from 'react-router-dom';
import {
    AppBar,
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Autocomplete,
    TextField,
    Grid,
    Button,
    useMediaQuery,
    Typography,
} from '@mui/material';
import { Person, CameraAlt, Menu, Close } from '@mui/icons-material';
import MenuList from '../../utils/menu.json';


// IMPORT COMPONENTS
import Login from '../Login.component';
import Inicio from '../inicio/Inicio.component';
import Registrar from '../registrar/Registrar.component';
import Transporte from '../transporte2/Transporte';
import Proveedores from '../Proveedores.component';
import Reparaciones from '../reparaciones/Reparaciones.component';
import Costos from '../costos/Costos.component';
import Reportes from '../reportes/Reportes.component';
import Sobre from '../Sobre.component';
import Otg from '../otg/Otg.component';
import Calidad from '../calidad/Calidad.component';
import Consultar from '../Consultar.component';
import Recibir from '../recibir/Recibir.component';
import Ticket from '../Ticket.component';
import Roadmap from '../Roadmap.component';
import Ajustes from '../ajustes/Ajustes';
import CostosFallas from '../costosfallas/CostosFallas.component';
import ColaboradorService from '../../services/Colaborador.service';
import { verificar } from '../../services/auth.service';
import { find } from '../../services/otg.service';
import DialogExpirado from './components/DialogExpirado.component';
import DialogOperation from './components/DialogOperation.component';
import RegistroFalla from '../ajustes/CargaFallas';
import { UserProvider } from '../../context/UserContext';

function Home() {
    const { showSnackbar } = useSnackbar();
    const handleClickSnackbar = (message, severity) => {
        showSnackbar({
            message,
            severity,
        });
    };
    const history = useHistory();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [mobileOpen, setMobileOpen] = useState(false);
    const [logueado, setLogueado] = useState(false);
    const [mostrar, setMostrar] = useState(false);
    const [sessionExpirada, setSessionExpirada] = useState(false);
    const [datosSession, setDatosSession] = useState({});
    const [otgsBusqueda, setOtgsBusqueda] = useState([]);

    const estadoModal = () => {
        setMostrar(!mostrar);
    };

    const routes = [
        { path: '/', component: Inicio, exact: true },
        { path: '/registrar', component: Registrar, exact: true },
        { path: '/transporte', component: Transporte, exact: true },
        { path: '/proveedores', component: Proveedores, exact: true },
        { path: '/reparaciones', component: Reparaciones, exact: true },
        { path: '/costos', component: Costos, exact: true },
        { path: '/reportes', component: Reportes, exact: true },
        { path: '/sobre', component: Sobre, exact: true },
        { path: '/calidad', component: Calidad, exact: true },
        { path: '/consultar', component: Consultar, exact: true },
        { path: '/recibir', component: Recibir, exact: true },
        { path: '/ticket', component: Ticket, exact: true },
        { path: '/otg/:idExterno', component: Otg, exact: true },
        { path: '/roadmap', component: Roadmap, exact: true },
        { path: '/ajustes', component: Ajustes, exact: true },
        { path: '/costosfallas', component: CostosFallas, exact: true },
        { path: '/ajustes', component: RegistroFalla, exact: true },
    ];

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const sessionToken = JSON.parse(localStorage.getItem('colaborador'));
                if (sessionToken) {
                    const responseVerificar = await verificar(sessionToken.token);
                    setDatosSession(responseVerificar.data);
                    setLogueado(true);
                    const responseFind = await find();
                    const datos = responseFind.data.map((otg) => ({
                        id_otg: otg.id_otg,
                        ap_paterno: otg.Cliente.ap_paterno,
                        ci: otg.Cliente.ci,
                        id_externo: otg.id_externo,
                    }));
                    setOtgsBusqueda(datos.reverse());
                } else {
                    setLogueado(false);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    setLogueado(false);
                    setSessionExpirada(true);
                    localStorage.removeItem('colaborador');
                }
            }
        };
        fetchUserData();
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const validateSession = async () => {
        try {
            await verificar(JSON.parse(localStorage.getItem('colaborador')).token);
        } catch (error) {
            if (error.response.status === 401) {
                setLogueado(false);
                setSessionExpirada(true);
                localStorage.removeItem('colaborador');
            }
        }
    };

    const drawer = (
        <div>
            <Toolbar
                sx={{
                    display: 'flex',
                    justifyContent: 'center', // Cambia a 'center' para centrar el contenido
                    alignItems: 'center',
                    height: '150px', // Ajusta la altura según tus necesidades
                    backgroundColor: '#yourColorCode', // Reemplaza 'yourColorCode' con el color que desees
                }}
            >
                <img
                    src="../img/Sinfronteras.png"
                    alt="Sinfronteras Logo"
                    style={{
                        maxHeight: isMobile ? '70%' : '100%', // Ajusta la altura para móviles
                        maxWidth: isMobile ? '70%' : '100%',  // Ajusta el ancho para móviles
                        objectFit: 'contain', // Asegura que la imagen mantenga sus proporciones
                    }}
                />
                {isMobile && (
                    <IconButton onClick={handleDrawerToggle}>
                        <Close sx={{ color: 'black' }} />
                    </IconButton>
                )}
            </Toolbar>
            <Divider />
            <div>
            <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'center', // Cambia a 'center' para centrar el contenido
                        alignItems: 'center',
                        height: '20px', // Ajusta la altura según tus necesidades
                        backgroundColor: '#00a082', // Reemplaza 'yourColorCode' con el color que desees
                    }}
                >
                    <img
                        src="../img/sf.png"
                        alt="Sinfronteras Logo"
                        style={{
                            maxHeight: isMobile ? '70%' : '80%', // Ajusta la altura para móviles
                            maxWidth: isMobile ? '70%' : '80%',  // Ajusta el ancho para móviles
                            objectFit: 'contain', // Asegura que la imagen mantenga sus proporciones
                        }}
                    />
                </Toolbar>
</div>

            <List>
                <ListItem>
                    <Autocomplete
                        sx={{ width: '80%', boxShadow: 1, borderRadius: 2 }}
                        onChange={() => {
                            handleClickSnackbar('Busqueda completada', 'info');
                        }}
                        options={otgsBusqueda}
                        getOptionLabel={(option) =>
                            `${option.id_otg} ${option.ap_paterno} ${option.ci}`
                        }
                        autoHighlight
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Otg/Paterno/Ci'
                                variant='outlined'
                                sx={{ boxShadow: 1, borderRadius: 2 }}
                            />
                        )}
                        renderOption={(props, option) => (
                            <RouterLink
                                key={option.id_externo}
                                to={`/otg/${option.id_externo}`}
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                <div {...props}>
                                    {`${option.id_otg} ${option.ap_paterno} ${option.ci}`}
                                </div>
                            </RouterLink>
                        )}
                    />
                    <IconButton
                        color='primary'
                        onClick={() => {
                            handleDrawerToggle();
                            estadoModal();
                        }}
                    >
                        <CameraAlt />
                    </IconButton>
                </ListItem>
                {MenuList.map((item) => {
                    const existe = item.permitido.some(
                        (dato) => dato === datosSession.id_tipo
                    );
                    return (
                        <ListItem
                            disabled={!existe}
                            button
                            key={item.id}
                            component={RouterLink}
                            to={item.url}
                            onClick={() => {
                                validateSession();
                                handleDrawerToggle(); // Cerrar el Drawer al seleccionar una opción
                            }}
                            sx={{
                                boxShadow: 2,
                                borderRadius: 1,
                                marginBottom: 1,
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}
                        >
                            <img src={item.icon} alt='Icon List' style={{ marginRight: 8 }} />
                            <ListItemText disableTypography={true} primary={item.name} />
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );

    return logueado ? (
        <Router>
            <Box sx={{ display: 'flex' }}>
            <AppBar
            sx={{
                width: { sm: `calc(100% - 240px)` },
                ml: { sm: `240px` },
                backgroundImage: 'linear-gradient(#00a082,#00a082)',
                boxShadow: 3,
                borderRadius: 1,
            }}
        >
            <Toolbar>
                <Button
                    sx={{
                        display: { sm: 'none' },
                        borderRadius: 1,
                    }}
                    startIcon={<Menu />}
                    onClick={handleDrawerToggle}
                >
                    Menu
                </Button>
                <Button onClick={() => history.goBack()}>Atrás</Button>
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: { xs: 'none', sm: 'flex' }, // Mostrar en escritorio, ocultar en móvil
                    }}
                >
                    <Typography variant="h6" noWrap fontWeight="bold">
                        GARANTÍAS Y REPARACIONES
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Person />
                    <Button
                        style={{ margin: 10 }}
                        onClick={() => {
                            ColaboradorService.logout();
                            setLogueado(false);
                        }}
                        to='/login'
                        sx={{ borderRadius: 1 }}
                    >
                        {datosSession.usuario}
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
                <Box
                    component='nav'
                    sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}
                >
                    <Drawer
                        variant={isMobile ? 'temporary' : 'permanent'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant='permanent'
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component='main'
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        width: { sm: `calc(100% - 240px)` },
                        boxShadow: 3,
                        borderRadius: 1,
                    }}
                >
                    <Toolbar />
                    <Grid item sx={{ paddingX: { sm: '1em', lg: '5rem' } }}>
                        <UserProvider>
                            <Switch>
                                {routes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        component={route.component}
                                        exact={route.exact}
                                    />
                                ))}
                            </Switch>
                        </UserProvider>
                    </Grid>
                </Box>
                <DialogOperation
                    open={mostrar}
                    SetMostrar={(value) => {
                        setMostrar(value);
                    }}
                />
            </Box>
        </Router>
    ) : (
        <>
            <DialogExpirado
                open={sessionExpirada}
                SetSessionExpirada={(value) => {
                    setSessionExpirada(value);
                }}
            />
            <Login />
        </>
    );
}

export default Home;
