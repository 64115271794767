import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { cambiarUnEstado, evaluarServicios } from '../../services/otg.service';
import { useSnackbar } from '../../context/SnackBarContext';

export default function DialogCancelar({
	open,
	close,
	id_otg,
	encontrarOtgs,
	modalInfo,
}) {
	// const { id_estado, estado, tipo_servicio, textoModal } = modalInfo;
	const [razon, setRazon] = useState('');
	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const cambiarEstado = async (
		id_otg,
		razon,
		id_estado,
		tipo_servicio,
		estado
	) => {
		try {
			await cambiarUnEstado({
				id_estado: id_estado,
				id_otg: id_otg,
				razon_cambio: razon,
			});

			await evaluarServicios({
				id_otg: id_otg,
				tipo_servicio: tipo_servicio,
				estado: estado,
			});

			encontrarOtgs();

			handleClickSnackbar('Registrado satisfactoriamente', 'success');
		} catch (err) {
			handleClickSnackbar(err.message, 'error');
		}
	};

	return (
		<Dialog onClose={close} open={open}>
			<DialogTitle>{modalInfo.textoModal}</DialogTitle>
			<DialogContent>
				<TextField
					margin={'dense'}
					fullWidth
					value={razon}
					onChange={(e) => {
						setRazon(e.target.value);
					}}
					multiline
					autoFocus
					// placeholder='Ej: El cliente rechazó el costo de la reparación'
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={close} variant='outlined' sx={{ backgroundColor: 'red', color: 'white' }}>
					Atras
				</Button>
				<Button
					variant='contained'
					onClick={() => {
						cambiarEstado(
							id_otg,
							razon,
							modalInfo.id_estado,
							modalInfo.tipo_servicio,
							modalInfo.estado
						);
					}}
				>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	);
}
