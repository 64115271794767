import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

export const findMaestro = async (datos) => {
	return await axios.post(
		`${API_URL}find_like_maestros`,
		{
			busqueda: datos,
		},
		{
			headers: {
				authorization: 'Bearer ' + token,
			},
		}
	);
};

export const findVariantes = (datos) => {
	return axios
		.post(
			`${API_URL}find_variantes`,
			{
				cod_referencia: datos,
			},
			{
				headers: {
					authorization: 'Bearer ' + token,
				},
			}
		)
		.then((response) => {
			return response.data[0].Variaciones;
		});
};

export const findVariacion = (idVariacion) => {
	return axios
		.post(
			`${API_URL}find_variante`,
			{
				id_variacion: idVariacion,
			},
			{
				headers: {
					authorization: 'Bearer ' + token,
				},
			}
		)
		.then((_response) => {
			const response = _response.data;

			const variacion = {
				id_variacion: response.id_variacion,
				nombre_variacion: response.nombre_producto,
				coleccion: response.Producto_maestro.Coleccion.nombre_coleccion,
				linea_producto:
					response.Producto_maestro.Linea_producto.nombre_linea_producto,
				temporada: response.Temporada.nombre_temporada,
				color: response.Color.nombre_color,
				talla: response.Talla.nombre_talla,
				ean: response.cod_barras,
				cod_unidad_negocio:
					response.Producto_maestro.Unidad_negocio.cod_unidad_negocio,
			};

			return variacion;
		});
};

// const functions = { findVariacion };
// export default functions;
