import React, { useState } from 'react';
import { Button, Paper, Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { useSnackbar } from '../../context/SnackBarContext';
import DialogEspere from './../../components/DialogEspere.component';

import axios from 'axios';
import { direccion } from '../../utils/localhost';
const token = JSON.parse(localStorage.getItem('colaborador'));

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

export default function UploadProducts() {
	const [selectedFile, setSelectedFile] = useState(null);
	const [loaderOpen, setLoaderOpen] = useState(false);
	const { showSnackbar } = useSnackbar();

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file || null);
		event.target.value = null;
	};

	const handleUpload = () => {
		setLoaderOpen(true);
		const formData = new FormData();
		formData.append('file', selectedFile);

		axios
			.post(`${direccion}products/load`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					authorization: 'Bearer ' + token.token,
				},
			})
			.then(({ data }) => {
				setSelectedFile(null);
				showSnackbar({
					message: data.message,
					severity: data.type || 'success',
				});
			})
			.catch((error) => {
				showSnackbar({
					message: `Error al subir el archivo.  
                    ${error.response?.data?.error || ''}
                    ${error.response?.data?.datosError?.campo || ''}`,
					severity: 'error',
				});
			})
			.finally(() => {
				setLoaderOpen(false);
			});
	};

	return (
		<Grid item>
			<DialogEspere open={loaderOpen} />
			<Paper elevation={3} sx={{ padding: 2 }}>
				<Typography align='center' variant='h6'>
					Carga de productos
				</Typography>

				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						component='label'
						tabIndex={-1}
						startIcon={<CloudUploadIcon />}
					>
						{selectedFile ? selectedFile.name : 'Seleccione un archivo'}
						<VisuallyHiddenInput type='file' onChange={handleFileChange} />
					</Button>

					<Button onClick={handleUpload} variant='contained' color='primary'>
						Cargar Productos
					</Button>
				</Box>
			</Paper>
		</Grid>
	);
}
