import React from 'react';
import { useHistory } from 'react-router-dom';

import { Avatar, Typography, List, ListItem, Grid } from '@mui/material';

export default function Sobre() {
	const history = useHistory();

	return (
		<>
			<Typography
				sx={{ display: 'none' }}
				onClick={() => {
					history.push({
						pathname: '/roadmap',
					});
				}}
				color='initial'
			>
				Roadmap
			</Typography>

			<Grid container justifyContent='center' alignItems='center' direction='column' spacing={2}>
				<Grid item>
					<Avatar
						alt='CALIDAD'
						src='../logo512-2.png'
						sx={{ width: '8rem', height: '8rem' }}
					/>
				</Grid>
				<Grid item>
					<Typography variant='h4' color='initial' align='center'>
						Garantías y Reparaciones
					</Typography>
				</Grid>
			</Grid>

			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.0.1 160824
			</Typography>

			<Grid container direction='column'>
			<Typography variant='subtitle1' color='initial'>
					Corrección de bug
				</Typography>

				<List>
					<ListItem>
					- No se registraba al usuario que realizaba cambios en los estados de OTG relacionados a Transporte.				
					</ListItem>
															
				</List>
				

			</Grid>

{/* //* VERSION 2.3.1 */}
<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.0 23072024
			</Typography>

			<Grid container direction='column'>
			<Typography variant='subtitle1' color='initial'>
					Cambio de nombre del sistema
				</Typography>

				<List>
					<ListItem>
					- Cambio del nombre del sistema a "Garantías y Reparaciones"				
					</ListItem>
															
				</List>
				<Typography variant='subtitle1' color='initial'>
					Interfaz de Usuario
				</Typography>

				<List>
					<ListItem>
					- Cambio de diseño de interfaz de usuario.				
					</ListItem>
					<ListItem>
					- Se incluyeron los nuevos colores que identifica a Garantías y Reparaciones.
					</ListItem>
					<ListItem>
					- Se incluyó el nuevo logo de SINFRONTERAS S.R.L. 
					</ListItem>
					<ListItem>
					- Se incluyó un nuevo logo de Garantías y Reparaciones.
					</ListItem>
					<ListItem>
					- Estados, fallas y nombres de tiendas se cambiaron a letras mayúsculas.
					</ListItem>
										
				</List>


				<Typography variant='subtitle1' color='initial'>
					Nuevas Funciones y Cambios
				</Typography>

				<List>
					<ListItem>
					- Se creó una nueva sección para ver las fallas registradas en sistema y sus costos estimados.				
					</ListItem>
					<ListItem>
					- Se creó una nueva sección donde se pueden registrar nuevos Proveedores, Transportadoras y Fallas.
					</ListItem>
					<ListItem>
					- Se implementó la carga masiva de nuevos productos.
					</ListItem>
					<ListItem>
					- Se eliminó el estado "Entregado a PDV".
					</ListItem>
					<ListItem>
					- Se agregó la Tienda de origen de la OTG en la sección de "Reparaciones".
					</ListItem>
										
				</List>

			</Grid>




			{/* //* VERSION 2.2.4 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.3.0 171223
			</Typography>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Transporte
				</Typography>

				<List>
					<ListItem>
						- Se re estructuró el componente de transporte para corregir un
						error en el que las otgs recibidas o entregadas no desaparecían una
						vez realizada la acción respectiva.
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Calidad
				</Typography>

				<List>
					<ListItem>
						- Se implementaron validaciones para impedir errores en la
						introducción de datos.
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Reportes
				</Typography>

				<List>
					<ListItem>
						- Se agregaron los campos descripción del servicio, para identificar
						mejor la falla del producto mediante reportes.
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 2.2.4 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.2.4 240523
			</Typography>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Hotfix
				</Typography>

				<List>
					<ListItem>
						- Se corrigió un error por una OTG con vários servicios no seguia
						adecuadamente el flujo normal del proceso.
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 2.2.3 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.2.3 180523
			</Typography>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Hotfix
				</Typography>

				<List>
					<ListItem>
						- Se corrigió un error por el cual una otg con varios servicios no
						cambiaba el estado a "Reparado" correctamente.
					</ListItem>
				</List>
			</Grid>
			{/* //* VERSION 2.2.2 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.2.2 150523
			</Typography>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Hotfix
				</Typography>

				<List>
					<ListItem>
						- Se corrigió un error por el cual la OTG no podía ser evaluada.
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 2.2.1 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.2.1 150523
			</Typography>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Hotfix
				</Typography>

				<List>
					<ListItem>
						- Se corrigió un error por el cual la página principal de OTG no
						cargaba correctamente.
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 2.2.0 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.2.0 140523
			</Typography>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					General
				</Typography>

				<List>
					<ListItem>- Cancelado ya no es un tipo de reparación</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Página principal
				</Typography>

				<List>
					<ListItem>- Corrección en tiempo final de la ficha de OTGS</ListItem>
					<ListItem>
						- La página principal ahora muestra la fecha del último movimiento
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Transporte{' '}
				</Typography>

				<List>
					<ListItem>- Mejoras en el componente de transporte</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Calidad{' '}
				</Typography>

				<List>
					<ListItem>
						- Ahora Calidad puede editar los datos de servicios, desde la ficha
						de OTGS
					</ListItem>
					<ListItem>- Mejoras en componente de costo de reparaciones</ListItem>
					<ListItem>
						- Ahora la ficha de reparaciones redirecciona a la ficha de la OTG
					</ListItem>
					<ListItem>
						- Se corrigió un error por el cual el costo de OTG cambiada al
						momento de cambiar el estado de la OTG
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Tienda{' '}
				</Typography>

				<List>
					<ListItem>
						- Ahora se valida que solo la tienda que generó la OTG puede
						finalizarla
					</ListItem>
					<ListItem>
						- La opción de escanear la OTG para recibirla en tiendas fue
						retirada
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 2.1.2 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.1.2 241022
			</Typography>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					HotFix
				</Typography>

				<List>
					<ListItem>
						- Se solucionó un problema por el cual no se podía editar el tipo de
						falla de la OTG
					</ListItem>
					<ListItem>
						- Se solucionó un problema por el cual se tomaba en cuenta las OTG
						ANULADAS como otgs en curso (Reporte total de OTGS).
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 2.1.1 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.1.1 241022
			</Typography>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					HotFix
				</Typography>

				<List>
					<ListItem>
						- Se implementó una validación en los reportes para evitar un error
						cuando la OTG tenga un servicio asignado.
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 2.1.0 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.1.0 231022
			</Typography>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Reportes
				</Typography>

				<List>
					<ListItem>
						- Se mejoraron los reportes de tiempo y el reporte total de OTGs.
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Mejoras en general
				</Typography>

				<List>
					<ListItem>
						- Se implementaron pequeñas mejoras en el modo en el que se muestran
						los estados de las OTGs.
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 2.0.4 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.0.4 101022
			</Typography>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					HotFix{' '}
				</Typography>

				<List>
					<ListItem>
						- Se corrigió un error por el cual Showroom no podía registrar
						OTG's.
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 2.0.3 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.0.3 051022
			</Typography>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Interfaz de usuario
				</Typography>

				<List>
					<ListItem>
						- Se actualizó la interfaz de usuario para una estandarización de
						todos los elementos en la aplicación.
					</ListItem>
				</List>
			</Grid>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Inicio
				</Typography>

				<List>
					<ListItem>
						- Se agregaron filtros para las OTGS en la pantalla principal.
					</ListItem>
					<ListItem>
						- Se agruparon las OTGS de la pantalla principal por ciudad y
						estado.
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 2.0.2 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.0.2 130722
			</Typography>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Ticket
				</Typography>

				<List>
					<ListItem>
						- Se agregó al ticket de impresión el tipo de servicio para
						facilitar el trabajo del encargado de almacén de calidad.
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 2.0.1 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.0.1 200522
			</Typography>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Reportes
				</Typography>

				<List>
					<ListItem>
						- Se corrige un error por el cual no se muestra decimales en los
						registros de costos del reporte de "Rendición de caja chica".
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 2.0.0 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 2.0.0 180522
			</Typography>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					General
				</Typography>

				<List>
					<ListItem>
						- Se implementó un nuevo estado de "Entregado a PDV" el cual será el
						estado registrado cuando la persona de transporte entregue una OTG a
						una tienda.
					</ListItem>
					<ListItem>
						- Se agregaron las opciones de búsqueda de OTG por apellido paterno
						del cliente o por número de carnet.
					</ListItem>
				</List>
			</Grid>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Tienda
				</Typography>

				<List>
					<ListItem>
						- Se quitó la opción "Garantía" del tipo de servicio al momento de
						registrar una OTG. Ahora en caso de aplicar a garantía se debe
						seleccionar en evaluación.
					</ListItem>
					<ListItem>
						- Se implementó la opción de registrar várias fallas por OTG, esto
						ayudará a realizar un mejor seguimiento a cada una de las falla de
						las OTGs.
					</ListItem>
					<ListItem>
						- Se cambió el Ticket de OTG para indicar la falla que se registró
						en la OTG y se quitó el tipo de reparación registrado por la tienda,
						esto con el fin de evitar mal entendidos con los clientes.
					</ListItem>
					<ListItem>
						- Se implementó una función mediante la cual la tienda tendrá que
						registrar los cambios de productos que se hacen por garantía.
					</ListItem>
				</List>
			</Grid>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Transporte
				</Typography>

				<List>
					<ListItem>
						- Se implementó la función de entregar la OTG a tiendas para
						delimitar mejor la responsabilidad de los usuarios en el sistema.
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Calidad
				</Typography>

				<List>
					<ListItem>
						- Se implementó la funcionalidad de evaluación de la OTG antes de
						que el proceso de reparación pueda iniciar.
					</ListItem>
					<ListItem>
						- Se implementaron los campos necesarios para registrar el costo de
						reparación, costo facturado, número de factura, número de
						recibo/factura, entre otros datos útiles para el seguimiento del
						proceso de reparación.
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Reportes
				</Typography>

				<List>
					<ListItem>
						- Se implementó el reporte "Cambios por garantía".
					</ListItem>
					<ListItem>
						- Se implementó el reporte "Rendición de caja chica".
					</ListItem>
					<ListItem>
						- Se implementó el reporte "Solicitudes pendientes".
					</ListItem>
					<ListItem>- Se implementó el reporte "Tiempos por estados".</ListItem>
					<ListItem>
						- Se implementó el reporte "Control interno de reparaciones".
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 1.2.0 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 1.2.0 151221
			</Typography>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					General
				</Typography>

				<List>
					<ListItem>
						- Se mejoró la seguridad de la aplicación cambiando el método de
						validación de peticiones al servidor.
					</ListItem>
					<ListItem>
						- Se agregó el dato de "Tipo de transporte" a todo el proceso de
						calidad, a partir de esta versión se almacenarán datos sobre si un
						transporte y transportadora son de ida o vuelta.
					</ListItem>
					<ListItem>
						- Se agregó un nuevo tipo de reparación "Reparación por garantía"
						con el cual se podrán filtrar todas las OTGs que correspondan a este
						tipo. Este nuevo tipo de reparación será asignado por el usuario de
						"Almacén de calidad" al momento de evaluar el producto.
					</ListItem>
					<ListItem>
						-Se quitó la validación que definía que solo las OTG en transporte
						podían ser recibidas en tiendas, esto para facilitar el registro de
						OTGs en regionales
					</ListItem>
				</List>
			</Grid>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Mejoras en interfaz
				</Typography>

				<List>
					<ListItem>
						- Se agregó el dato de "Tipo de transporte" a todo el proceso de
						calidad, a partir de esta versión se almacenarán datos sobre si un
						transporte y transportadora son de ida o vuelta.
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Transporte
				</Typography>

				<List>
					<ListItem>
						- Se agregó un el datos de "Tipo de transporte" a todo el proceso de
						calidad, a partir de esta versión se almacenarán datos sobre si un
						transporte y transportadora son de ida o vuelta.
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Reportes
				</Typography>

				<List>
					<ListItem>
						- Se agregó un nuevo reporte de OTGs pendientes, en los cuales se
						consultará las OTG que aún no concluyeron el flujo de reparación
						completo.
					</ListItem>
					<ListItem>
						- Se agregó un nuevo reporte "Costo" por el cual los usuarios podrán
						identificar el costo de la reparación de la OTG (si corresponde).
					</ListItem>
					<ListItem>
						- Se agregó un botón en la tab de reportes con el cual se podrán
						generar "Reportes a fecha" para evitar tener que introducir fecha de
						inicio y fin para generar el reporte.
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 1.1.1 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 1.1.1 26821
			</Typography>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					General
				</Typography>

				<List>
					<ListItem>
						- Se implementó una función la cual valida que la sesión del usuario
						siga siendo válida, esta función se ejecuta cada vez que los
						usuarios cargan o recargan una tab del sistema.
					</ListItem>
					<ListItem>
						- Se extendió el tiempo de validez de la sesión de usuario de 24
						horas a 72 horas.
					</ListItem>
				</List>
			</Grid>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Registro de clientes
				</Typography>

				<List>
					<ListItem>
						- Se mejoró la validación de los campos para no permitir que se
						registren ej. letras en campos que solo deberían admitir números,
						esto solucionará un error por el cual no se registraba un cliente el
						momento de realizar el registro de la OTG.
					</ListItem>
					<ListItem>
						- Se implementó un nuevo mensaje de error, que se mostrará cuando el
						cliente no haya podido ser creado por alguna razón.
					</ListItem>
					<ListItem>
						- Se corrigió un error que no permitía dejar el email del cliente en
						blanco cuando se requería hacer una edición.
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Transporte
				</Typography>

				<List>
					<ListItem>
						- En el tab de transporte se agregó una nueva sección en la cual los
						usuarios de transporte podrán visualizar las otgs que se encuentren
						en estado de transporte en su regional. Esto ayudará el usuario de
						transporte a realizar un mejor control de las OTGS que no estén
						siendo marcadas como recibidas en el momento correcto.
					</ListItem>
					<ListItem>
						- Se implementó un popup que mostrará los datos de envío registrados
						al momento de enviar una otg por transportadora, se puede acceder a
						este popup clickeando el número de la OTG en la sección de
						'Transportadora' del tab de transporte.
					</ListItem>
					<ListItem>
						- Se implementó el campo número de cajas que será llenado al momento
						de enviar Otgs por transportadoras.
					</ListItem>
					<ListItem>
						- El campo número guía ahora admite números, símbolos y letras en
						lugar de solo números.
					</ListItem>
				</List>
			</Grid>

			{/* //* VERSION 1.1.0 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 1.1.0 2821
			</Typography>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Registro de la OTG
				</Typography>

				<List>
					<ListItem>
						- El la página de ticket de agregó un botón para registrar otra otg
						con los mismos datos del cliente que fue registrado en esa otg.
					</ListItem>
					<ListItem>
						- Se agregó la funcionalidad de agregar fotos a las otgs creadas
						anteriormente en caso de que no se pueda agregar fotos del producto
						en el momento del registro.
					</ListItem>
					<ListItem>
						- Se agregó la falla de broche en la lista de fallas disponibles en
						el registro.
					</ListItem>
					<ListItem>
						- Cuando se buscaba un producto maestro que no existia, el popup con
						el texto "Espere por favor" no desaparecía. Solucionamos le problema
						y ahora se informará al usuario que el producto que está buscando no
						existe.
					</ListItem>
					<ListItem>
						- Cuando se terminaba el registro de la otg y se pasaba a la
						impresión de la ficha de la OTG esta se imprimía en blanco, esto se
						debe a que la conexión a internet no está siendo del todo estable,
						para mitigar esto se implemenó un popup de carga mientras se
						obtienen los datos para la impresión de la ficha de la OTG.
					</ListItem>
					<ListItem>
						- A solicitud de Calidad se implementó un nuevo campo: "Fecha de
						compra" este campo permitirá evaluar mas facilmente si el producto
						califica como reparación o garantía. El campo es obligatorio y
						deberá ser siempre llenado por las tiendas.
					</ListItem>
					<ListItem>
						- Se identificó y solucionó un problema por el cual el modal de
						espere, no desaparecía al buscar el CI del cliente cuando se le
						agregaba una letra ( ejemplo: 49c) .
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Transporte
				</Typography>

				<List>
					<ListItem>
						- Se mejoró la experiencia de usuario en el apartado de TRANSPORTE,
						ahora se indicará de cuando los datos están siendo obtenidos del
						servidor y mientras los datos se guardan, esto es especialmente util
						en situaciones en las que no se cuente con una buena conexión a
						internet y evitará problemas de doble registro.
					</ListItem>
					<ListItem>
						- Se alinearón los datos de las OTGS que están listas para ser
						recogidas, esto debería ayudar a que sea mas fácil seleccionarlas.
					</ListItem>
				</List>
			</Grid>

			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Calidad
				</Typography>

				<List>
					<ListItem>
						- Se integró un nuevo componente en el tab de calidad para que
						calidad pueda recibir otgs directamente desde tiendas, siempre y
						cuando la tienda haya marcado que esta no necesita transporte.
					</ListItem>
					<ListItem>
						- Al igual que el tab de transporte, se mejoró la experiencia de
						usuario en todos los procesos que involucran a CALIDAD, ahora se
						indicará de cuando los datos están siendo obtenidos del servidor y
						mientras los datos se guardan. Los tabs que fueron mejorados son:
						Calidad, Reparaciones y Costos.
					</ListItem>
					<ListItem>
						- Se agregó información de la fecha en la nota de entrega.
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					General
				</Typography>

				<List>
					<ListItem>
						- Se identificó un error por el cual en ocasiones no se registraba
						el cliente al momento del registro, es posible que esto se deba que
						se excedían los caracteres disponibles para el registro de teléfono
						o celular el cliente, se realizó un cambio con el cual este problema
						debería dejar de ocurrir y se monitoreará si el error fue
						solucionado.
					</ListItem>
					<ListItem>
						- Se agregó un nuevo estado de eliminado esto permitirá que el
						correlativo de una OTG no se pueda y no se necesite eliminar
						completamente la OTG de la base de datos.
					</ListItem>
					<ListItem>
						- Se agregó una página de roadmap donde se mencionarán los cambios a
						futuro en la aplicación.
					</ListItem>
					<ListItem>
						- Se implementaron iconos de carga que se mostrarán cada vez que se
						estén obteniendo listas de OTGs. Esta mejora se aplica a los tabs
						de: Inicio, Reparaciones y costos
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Notificaciones
				</Typography>

				<List>
					<ListItem>
						- Ahora el correo de notificaciones se muestra correctamente y el
						link a whatsapp fue solucionado.
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Reportes
				</Typography>

				<List>
					<ListItem>
						- Se implementó una vista previa en los reportes para poder
						consultarlos sin tener que descargarlos.
					</ListItem>
					<ListItem>
						- Se solucionó un error por el cual el rango de fecha de reportes no
						se estaba tomando corectamente.
					</ListItem>
				</List>
			</Grid>
			{/* //* VERSION 1.0.1 */}
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 1.0.1 7621
			</Typography>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Inicio de sesión:
				</Typography>

				<List>
					<ListItem>
						- Se corrigió un error por el cual las mayúsculas no se mostraban el
						el input de nombre de usuario.
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Manejo de versiones:
				</Typography>

				<List>
					<ListItem>
						- Ahora se mostrará un mensaje en el navegador para indicar que
						existe una nueva versión de calidad.
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Ficha de la OTG
				</Typography>

				<List>
					<ListItem>
						- Se quitó el campo de costo aproximado de reparación y se agregó el
						costo final de la reparación registrado por calidad.
					</ListItem>
					<ListItem>
						- Se implementó una nueva sección en la ficha, en esta sección la
						tienda puede indicar si la OTG necesita ser recogida por el camión
						de transporte.
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Registro de la OTG
				</Typography>

				<List>
					<ListItem>
						- Se implementaron modals de carga en el registro de la OTG mientras
						se buscan datos del cliente, producto, etc.
					</ListItem>
					<ListItem>
						- Se mejoró el proceso de busqueda de producto, por lo que ahora la
						busqueda será un poco mas rápida.
					</ListItem>
				</List>
			</Grid>
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 1.0.0 1521
			</Typography>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Tiendas:
				</Typography>

				<List>
					<ListItem>
						- Ahora el campo de descripción de servicio es obligatorio
					</ListItem>
					<ListItem>
						- Agregado un tipo de servicio de evaluación para el registro de la
						OTG
					</ListItem>
					<ListItem>
						- Se corrigió un error por el cual el mensaje de tipo de servicio no
						se estaba mostrando
					</ListItem>
				</List>
			</Grid>
			<Grid container>
				<Typography variant='subtitle1' color='initial'>
					Calidad:
				</Typography>

				<List>
					<ListItem>
						- Cuando el producto califica como reparación o garantía se envía
						una notificación al cliente
					</ListItem>
					<ListItem>
						- Al cambiar el producto a garantía, en la fase de evaluación, se
						deberá ingresar la razón de la garantía y el costo de la reparación
						de garantía
					</ListItem>
					<ListItem>
						- Se agregó el campo de código Ean del producto en el reporte de
						productos en reparación
					</ListItem>
				</List>
			</Grid>
			<Grid container>
				<Typography variant='subtitle1' color='initial'>
					Mejoras en experiencia de usuario:
				</Typography>

				<List>
					<ListItem>
						- Se cambiaron los iconos de calidad y costos en el sidebar de la
						aplicación
					</ListItem>
					<ListItem>
						- Ahora solo se aceptan minusculas en el inicio de sessión
					</ListItem>
				</List>
			</Grid>
			<Grid container>
				<Typography variant='subtitle1' color='initial'>
					Areas de control
				</Typography>

				<List>
					<ListItem>
						- Se implementó la generación de un reporte con todos los históricos
						de OTG requeridos por fechas
					</ListItem>
				</List>
			</Grid>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Otros
				</Typography>

				<List>
					<ListItem>
						- Se corrigió un error por el cual las tallas de los productos no se
						mostraban correctamente
					</ListItem>
				</List>
			</Grid>
			<Typography align='center' variant='h6' color='initial'>
				Notas de version 0.2.0 (Beta) 22521
			</Typography>
			<Grid container direction='column'>
				<Typography variant='subtitle1' color='initial'>
					Tiendas:
				</Typography>

				<List>
					<ListItem>
						- Ahora la tienda puede editar los datos del cliente
					</ListItem>
					<ListItem>
						- Se muestra correctamente la unidad de negocio del producto
						seleccionado al momento del registro
					</ListItem>
					<ListItem>
						- Agregado campo para registrar el nombre de la persona que registra
						una OTG
					</ListItem>
					<ListItem>
						- Solucionado error que no permitia dejar espacios en blanco en el
						momento del registro de un cliente
					</ListItem>
					<ListItem>
						- Solucionado error por el cual no se podían agregar fotos desde un
						teléfono
					</ListItem>
					<ListItem>
						- Agregados campos de costo aproximado de reparación
					</ListItem>
					<ListItem>
						- Agregados campos de NIT y razón social al momento de registro
					</ListItem>
					<ListItem>
						- Ahora puede buscar las OTGs por nombre del producto
					</ListItem>
					<ListItem>
						- Ahora una OTG solo puede ser recibida en tienda si está en estado
						de transporte
					</ListItem>
				</List>
			</Grid>
			<Grid container>
				<Typography variant='subtitle1' color='initial'>
					Transporte:
				</Typography>
				<List>
					<ListItem>
						- Ahora el usuario Transporte puede ver el origen y destino de las
						OTGS
					</ListItem>
					<ListItem>
						- Solucionado error que marcaba las OTGs que no estaban siendo
						registradas por el usuario transporte
					</ListItem>
				</List>
			</Grid>
			<Grid container>
				<Typography variant='subtitle1' color='initial'>
					Calidad:
				</Typography>
				<List>
					<ListItem>
						- Ahora el usuario calidad puede editar los datos de la otg desde la
						ficha de calidad
					</ListItem>
					<ListItem>
						- Agregado el campo observaciones en la ficha de la OTG
					</ListItem>
					<ListItem>
						- Agregada funcion de cambio de estados para el usuario de calidad
					</ListItem>
					<ListItem>
						- El estado de cancelado fue agregado a la lista de estados de
						calidad
					</ListItem>

					<ListItem>
						- Agregada función de generar reporte para ver todas las otgs en
						estado de reparación
					</ListItem>
					<ListItem>
						- Agregada función de generar reporte de todas las OTGS donde puede
						consultar estado, tienda, entre otros
					</ListItem>
				</List>
			</Grid>
			<Grid container>
				<Typography variant='subtitle1' color='initial'>
					Mejoras en experiencia de usuario:
				</Typography>
				<List>
					<ListItem> - Cambio de posición de tab proveedores a costo</ListItem>
					<ListItem> - El input de busqueda ya funciona correctamente</ListItem>
					<ListItem>
						- La funcion de recuperar contraseñas ya funciona correctamente
					</ListItem>
					<ListItem>
						- Ahora se puede ingresar los datos del login con la tecla enter
					</ListItem>
					<ListItem>
						- El sidebar se cierra correctamente cuando el usuario selecciona la
						pestaña
					</ListItem>
					<ListItem>
						- Se informa correctamente que la sessión del usuario a caducado y
						debe iniciar sessión
					</ListItem>
					<ListItem>
						- Se implementarion mensajes cuando el colaborador no registra
						correctamente los datos
					</ListItem>
				</List>
			</Grid>
			<Grid container>
				<Typography variant='subtitle1' color='initial'>
					Otros:
				</Typography>
				<List>
					<ListItem>
						- FavIcon y Title actualizados con nombre y logo del proyecto
						Calidad
					</ListItem>
					<ListItem>
						- Solución de error por el cual no se mostraban las OTGS
					</ListItem>
					<ListItem> - Solución de error en las URLs de Calidad</ListItem>
					<ListItem>
						- Se agregó un boton en la ficha de OTG para reimprimir el ticket de
						la OTG
					</ListItem>
					<ListItem>
						- Ahora calidad es una PWA por lo que puedes instalarla en tu
						teléfono o computadora, como si fuera una aplicacion nativa
					</ListItem>
				</List>
			</Grid>
		</>
	);
}
