import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useHistory } from 'react-router-dom';

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Chip,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Paper,
	Select,
	Typography,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import otgService from '../../services/otg.service';

import { styled } from '@mui/material/styles';

import _ from 'lodash';
import { nanoid } from 'nanoid';
import AlertDialog from './components/Alert.component';

const PaperCustom = styled(Paper)`
	padding: 1rem 2rem;
	cursor: 'pointer';
`;

export default function Inicio() {
	const history = useHistory();

	const [listaOtgs, setListaOtgs] = useState([]);

	const [circularProgress, setCircularProgress] = useState(true);

	const [mensaje, setMensaje] = useState({
		mostrar: false,
		texto: '',
		tipo: 'error',
	});

	const ciudades = ['La Paz', 'Santa Cruz', 'Cochabamba'];
	const estados = [
		'Registrado',
		'transporte',
		'calidad',
		'reparacion',
		'cancelado',
		'garantia',
		'en tienda',
	];
	const almacenes = [
		'centro de moda',
		'shopping norte',
		'multicine',
		'megacenter',
		'alameda',
		'illampu',
		'gran plaza',
		'montenegro',
		'ayacucho',
		'cervantes',
		'hupermall',
		'aranjuez',
		'casco viejo',
		'ventura mall',
		'las brisas',
		'fidalga',
		'mutualista',
	];

	const [ciudadSeleccionada, setCiudadSeleccionada] = useState([]);
	const [estadosSeleccionados, setEstadosSeleccionados] = useState([]);
	const [almacenesSeleccionados, setAlmacenesSeleccionados] = useState([]);

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		setCiudadSeleccionada(typeof value === 'string' ? value.split(',') : value);
	};

	const changeEstados = (event) => {
		const {
			target: { value },
		} = event;
		setEstadosSeleccionados(
			typeof value === 'string' ? value.split(',') : value
		);
	};

	const changeAlmacenes = (event) => {
		const {
			target: { value },
		} = event;
		setAlmacenesSeleccionados(
			typeof value === 'string' ? value.split(',') : value
		);
	};

	const buscarOtgs = () => {
		otgService
			.findAllOtgs()
			.then((response) => {
				const arrayBase = response.data;

				let filtradoCiudades = [];
				let filtardoEstados = [];
				let filtradoAlmacenes = [];

				if (ciudadSeleccionada.length !== 0) {
					ciudadSeleccionada.forEach((ciudad) => {
						// let data = eliminarCiudad(arrayBase, ciudad);
						// filtradoCiudades.push(...data);

						let data = _.remove(arrayBase, (otg) => {
							const ciudadBusqueda =
								otg.Colaborador.Ubicacione.Ciudad.nombre_ciudad;
							return ciudadBusqueda === ciudad;
						});

						filtradoCiudades.push(...data);
					});
				} else {
					filtradoCiudades = arrayBase;
				}

				if (estadosSeleccionados.length !== 0) {
					estadosSeleccionados.forEach((estado) => {
						const data = _.filter(filtradoCiudades, (otg) => {
							return otg.Estado.nombre_estado === estado;
						});
						filtardoEstados.push(...data);
					});
				} else {
					filtardoEstados = filtradoCiudades;
				}

				if (almacenesSeleccionados.length !== 0) {
					almacenesSeleccionados.forEach((almacen) => {
						const data = _.filter(filtardoEstados, (otg) => {
							return otg.Colaborador.Ubicacione.nombre_ubicacion === almacen;
						});
						filtradoAlmacenes.push(...data);
					});
				} else {
					filtradoAlmacenes = filtardoEstados;
				}

				const data = ordenarArray(filtradoAlmacenes);

				if (data.length !== 0) {
					setMensaje({
						mostrar: true,
						texto: 'Filtro aplicado',
						tipo: 'success',
					});
				} else {
					setMensaje({
						mostrar: true,
						texto: 'No se encontraron Otgs con esos parámetros de busqueda',
						tipo: 'info',
					});
				}

				setListaOtgs(data);
			})
			.catch((err) => {
				console.log(err);
				setMensaje({
					mostrar: true,
					texto: 'Error al aplicar el filtro deseado',
					tipo: 'error',
				});
			});
	};

	const ordenarArray = (array) => {
		const _otgsCiudad = _.groupBy(
			array,
			'Colaborador.Ubicacione.Ciudad.nombre_ciudad'
		);

		const otgsCiudad = Object.values(_otgsCiudad);

		return otgsCiudad.map((ciudades) => {
			return Object.values(_.groupBy(ciudades, 'Estado.nombre_estado'));
		});
	};

	useEffect(() => {
		otgService.findAllOtgs().then((response) => {
			const ordenado = ordenarArray(response.data);
			setListaOtgs(ordenado);
			setCircularProgress(false);
		});
	}, []);

	return (
		<>
			<AlertDialog
				open={mensaje.mostrar}
				message={mensaje.texto}
				SetModalEntregar={(value) => {
					setMensaje({ ...mensaje, mostrar: false });
				}}
			/>

			<Grid container wrap='wrap' justifyContent='center'>
				<FormControl sx={{ m: 1, width: 300 }}>
					<InputLabel>Ciudades</InputLabel>
					<Select
						multiple
						value={ciudadSeleccionada}
						onChange={handleChange}
						input={<OutlinedInput label='Ciudades' />}
						renderValue={(selected) => (
							<Box
								sx={{
									display: 'flex',
									flexWrap: 'wrap',
									gap: 0.5,
								}}
							>
								{selected.map((value) => (
									<Chip size='small' key={value} label={value.toUpperCase()} />
								))}
							</Box>
						)}
					>
						{ciudades.map((name) => (
							<MenuItem key={name} value={name}>
								{name.toUpperCase()}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl sx={{ m: 1, width: 300 }}>
    <InputLabel>Estados</InputLabel>
    <Select
        multiple
        value={estadosSeleccionados}
        onChange={changeEstados}
        input={<OutlinedInput label='Estados' />}
        renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                    <Chip size='small' key={value} label={value.toUpperCase()} />
                ))}
            </Box>
        )}
    >
        {estados.map((name) => (
            <MenuItem key={name} value={name}>
                {name.toUpperCase()}
            </MenuItem>
        ))}
    </Select>
</FormControl>

				<FormControl sx={{ m: 1, width: 300 }}>
					<InputLabel>Tiendas</InputLabel>
					<Select
						multiple
						value={almacenesSeleccionados}
						onChange={changeAlmacenes}
						input={<OutlinedInput label='Tiendas' />}
						renderValue={(selected) => (
							<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
								{selected.map((value) => (
									<Chip size='small' key={value} label={value.toUpperCase()} />
								))}
							</Box>
						)}
					>
						{almacenes.map((name) => (
							<MenuItem key={name} value={name}>
								{name.toUpperCase()}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<Button size='small' variant='contained' onClick={buscarOtgs}>
					Buscar
				</Button>
			</Grid>

			{circularProgress ? (
				<Grid container>
					<CircularProgress style={{ margin: 'auto' }}></CircularProgress>
				</Grid>
			) : (
				listaOtgs.map((ciudad) => {
					return (
						<Accordion key={nanoid()} sx={{ my: 2 }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
            <span style={{ fontWeight: 'bold' }}>{`${ciudad[0][0].Colaborador.Ubicacione.Ciudad.nombre_ciudad} - `}</span>
            <span style={{ fontWeight: 'bold' }}>En curso: </span>
            <span style={{ fontWeight: 'bold' }}>
                {(function () {
                    let contador = 0;

                    for (const estado of ciudad) {
                        contador = contador + estado.length;
                    }

                    return contador;
                })()}
            </span>
        </Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ margin: '0rem' }}>
        {ciudad.map((estado) => {
            return (
                <Accordion key={nanoid()} sx={{ width: '100%' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                            {`${estado[0].Estado.nombre_estado.toUpperCase()} ${estado.length}`}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ margin: '0rem' }}>
    {estado.map((otg, index) => (
        <PaperCustom
            key={nanoid()}
            onClick={() => {
                history.push(`/otg/${otg.id_externo}`);
            }}
            sx={{ mb: index < estado.length - 1 ? 2 : 0 }} // Aplica margen inferior de 2 (o el valor que prefieras) excepto al último elemento
        >
            <Typography
                component={Grid}
                item
                container
                justifyContent='center'
                fontWeight='bold'
            >
                Número de OTG: {otg.id_otg}
            </Typography>

            <Grid
                item
                container
                justifyContent='space-between'
            >
                <Typography>
                    Estado: {otg.Estado.nombre_estado.toUpperCase()}
                </Typography>
                <Typography>
                    Ubicación: {otg.Estado.ubicacion.toUpperCase()}
                </Typography>
            </Grid>

            <Grid
    item
    container
    justifyContent='space-between'
>
    <Typography>
        Tienda: {otg.Colaborador.Ubicacione.nombre_ubicacion.toUpperCase()}
    </Typography>
    <Typography>
        Registro: {moment(otg.createdAt).format('DD-MM-YY')}
    </Typography>
</Grid>
        </PaperCustom>
    ))}
</AccordionDetails>
                </Accordion>
            );
        })}
    </AccordionDetails>
</Accordion>
					);
				})
			)}
			{/* <Snackbar
				open={mensaje.mostrar}
				autoHideDuration={3000}
				onClose={() => setMensaje({ ...mensaje, mostrar: false })}
				anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
			>
				<Alert
					onClose={() => {
						setMensaje({ ...mensaje, mostrar: false });
					}}
					severity={mensaje.tipo}
				>
					{mensaje.texto}
				</Alert>
			</Snackbar> */}
		</>
	);
}
