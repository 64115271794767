import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

export const findHistorico = async (id_otg) => {
	return await axios.post(
		`${API_URL}find_historico`,
		{ id_otg: id_otg },
		{
			headers: {
				authorization: 'Bearer ' + token,
			},
		}
	);
};
