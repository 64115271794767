import React, { useState } from 'react';

import { useSnackbar } from '../../../context/SnackBarContext';

import { Typography, Button, TextField, Grid, Card } from '@mui/material';

import { Edit } from '@mui/icons-material';
import { buscarCliente } from '../../../services/clientes.services';
import DialogEspere from './../../../components/DialogEspere.component';
import {
	crearCliente,
	updateCliente,
} from './../../../services/clientes.services';

export default function RegistrarCliente(props) {
	const { setIdCliente } = props;

	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const [editarCliente, setEditarCliente] = useState(false);

	const [ci, setCi] = useState('');

	const actualizarInputCliente = (e) => {
		setDatosCliente({
			...datosCliente,
			[e.target.name]: e.target.value,
		});
	};

	const baseCliente = {
		id_cliente: '',
		ci: '',
		primer_nombre: '',
		segundo_nombre: '',
		ap_paterno: '',
		ap_materno: '',
		celular: '',
		correo: '',
		nit: '',
		razon_social: '',
	};

	const [datosCliente, setDatosCliente] = useState(baseCliente);

	const [mostrarEspere, setMostrarEspere] = useState(false);

	const [action, setAction] = useState('guardar');

	const handleBuscar = () => {
		setMostrarEspere(true);

		buscarCliente(ci)
			.then((response) => {
				setMostrarEspere(false);
				setEditarCliente(true);

				setDatosCliente({
					...response.data,
				});

				handleClickSnackbar('Cliente encontrado', 'success');

				setAction('siguiente');
			})
			.catch((err) => {
				setMostrarEspere(false);

				setDatosCliente(baseCliente);

				handleClickSnackbar(err.response.data.message, 'error');

				setEditarCliente(false);
			});
	};

	const registrarNuevoCliente = () => {
		setMostrarEspere(true);

		crearCliente(datosCliente)
			.then(({ data }) => {
				setMostrarEspere(false);

				setIdCliente(data.id_cliente);

				handleClickSnackbar('Nuevo cliente registrado', 'success');
			})
			.catch((err) => {
				setMostrarEspere(false);

				handleClickSnackbar(err.response.data.message, 'error');
			});
	};

	const actualizarCliente = () => {
		setMostrarEspere(true);
		updateCliente(datosCliente)
			.then(() => {
				setMostrarEspere(false);
				setAction('siguiente');

				handleClickSnackbar('Datos editados', 'success');
			})
			.catch((err) => {
				setMostrarEspere(false);

				handleClickSnackbar(err.response.data.message, 'error');
			});
	};

	const siguiente = () => {
		setIdCliente(datosCliente.id_cliente);
	};

	return (
		<>
			<Card sx={{ padding: '1rem' }}>
				<Typography variant='h6'>Buscar Cliente:</Typography>
				<Grid container justifyContent='center'>
					<TextField
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleBuscar();
							}
						}}
						type='number'
						name='ci'
						value={ci}
						label='CI del cliente'
						onChange={(e) => {
							setCi(e.target.value);
						}}
					/>

					<Button
						sx={{ margin: 'auto 1rem' }}
						onClick={handleBuscar}
						variant='contained'
						color='primary'
					>
						Buscar
					</Button>
				</Grid>
			</Card>

			<Typography
				align='center'
				variant='h6'
				color='initial'
				sx={{ margin: '1rem auto' }}
			>
				Datos del cliente
				<Edit
					onClick={() => {
						setEditarCliente(false);

						setAction('editar');
					}}
				/>
			</Typography>

			<Grid
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(2, 1fr)',
					gap: 2,
					gridTemplateRows: 'auto',
					gridTemplateAreas: `"ci ci"
            "primer segundo"
            "paterno materno"
            "correo celular"
            "nit razon "
            "boton boton "
            `,
				}}
			>
				<TextField
					sx={{ gridArea: 'ci' }}
					required
					disabled={editarCliente}
					type='number'
					name='ci'
					value={datosCliente.ci}
					label='Cedula de identidad'
					onChange={actualizarInputCliente}
					helperText='Campo obligatorio'
				/>

				<TextField
					sx={{ gridArea: 'primer' }}
					disabled={editarCliente}
					value={datosCliente.primer_nombre}
					label='Primer Nombre'
					name='primer_nombre'
					onChange={actualizarInputCliente}
				/>

				<TextField
					sx={{ gridArea: 'segundo' }}
					disabled={editarCliente}
					value={datosCliente.segundo_nombre}
					label='Segundo Nombre'
					name='segundo_nombre'
					onChange={actualizarInputCliente}
				/>
				<TextField
					sx={{ gridArea: 'paterno' }}
					required
					inputProps={{
						pattern: '[a-zA-Z]+',
						title: 'Solo se aceptan letras',
					}}
					disabled={editarCliente}
					value={datosCliente.ap_paterno}
					label='Apellido Paterno '
					name='ap_paterno'
					onChange={actualizarInputCliente}
					helperText='Campo obligatorio'
				/>
				<TextField
					sx={{ gridArea: 'materno' }}
					disabled={editarCliente}
					value={datosCliente.ap_materno}
					label='Apellido Materno'
					name='ap_materno'
					onChange={actualizarInputCliente}
				/>

				<TextField
					sx={{ gridArea: 'celular' }}
					disabled={editarCliente}
					value={datosCliente.celular}
					label='Celular'
					name='celular'
					onChange={actualizarInputCliente}
					helperText='Se enviarán notificaciones por SMS'
				/>

				<TextField
					sx={{ gridArea: 'correo' }}
					disabled={editarCliente}
					value={datosCliente.correo}
					label='Correo'
					name='correo'
					onChange={actualizarInputCliente}
					helperText='Se enviarán notificaciones por correo'
				/>

				<TextField
					sx={{ gridArea: 'nit' }}
					disabled={editarCliente}
					value={datosCliente.nit}
					label='NIT'
					name='nit'
					onChange={actualizarInputCliente}
				/>

				<TextField
					sx={{ gridArea: 'razon' }}
					disabled={editarCliente}
					value={datosCliente.razon_social}
					label='Razón Social'
					name='razon_social'
					onChange={actualizarInputCliente}
				/>

				{/* Action buttons */}

				{action === 'guardar' && (
					<Button
						sx={{ gridArea: 'boton' }}
						size='large'
						variant='contained'
						color='primary'
						onClick={registrarNuevoCliente}
					>
						Registrar Nuevo Cliente
					</Button>
				)}

				{action === 'editar' && (
					<Button
						sx={{ gridArea: 'boton' }}
						size='large'
						variant='contained'
						color='primary'
						onClick={actualizarCliente}
					>
						Guardar Edición
					</Button>
				)}

				{action === 'siguiente' && (
					<Button
						sx={{ gridArea: 'boton' }}
						size='large'
						variant='contained'
						color='primary'
						onClick={siguiente}
					>
						Siguiente
					</Button>
				)}
			</Grid>

			<DialogEspere open={mostrarEspere} />
		</>
	);
}
