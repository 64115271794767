import React, { useEffect, useState } from 'react';

import {
	Grid,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
	IconButton,
	TextField,
} from '@mui/material';

import { ExpandMore, Edit } from '@mui/icons-material';

import { updateCliente } from '../../../services/clientes.services';

export default function Cliente(props) {
	const [cliente, setCliente] = useState('');

	useEffect(() => {
		setCliente(props.cliente);
	}, [props]);

	const [editarCliente, setEditarCliente] = useState(!false);

	const actualizarCliente = (e) => {
		setCliente({
			...cliente,
			[e.target.name]: e.target.value,
		});
	};

	const guardarCliente = () => {
		updateCliente(cliente)
			.then(() => {
				props.getResponse({
					message: 'Cambio exitoso',
					typeLog: 'success',
				});
				setEditarCliente(!editarCliente);
			})
			.catch((err) => {
				props.getResponse({
					message: err.response.data.message,
					typeLog: 'error',
				});
			});
	};

	return (
		cliente !== '' && (
			<Accordion>
				<Grid container justifyContent='space-around'>
					<Typography sx={{ mt: 1 }} variant='subtitle1' align='center'>
						Información de cliente
						<IconButton
							size='small'
							onClick={() => {
								setEditarCliente(!editarCliente);
							}}
						>
							<Edit />
						</IconButton>
					</Typography>

					<AccordionSummary expandIcon={<ExpandMore />}></AccordionSummary>
				</Grid>

				<AccordionDetails>
					<Grid
						sx={{
							paddingX: { sm: '1em', lg: '10rem' },
							display: 'grid',
							gridTemplateColumns: 'repeat(2, 1fr)',
							gap: 2,
							gridTemplateRows: 'auto',
							gridTemplateAreas: `"ci ci"
        "primer segundo"
        "paterno materno"
        "correo celular"
        "nit razon "
        "boton boton "
        `,
						}}
					>
						<TextField
							sx={{ gridArea: 'ci' }}
							required
							disabled
							type='number'
							name='ci'
							value={cliente.ci}
							label='Cedula de identidad'
							onChange={actualizarCliente}
							helperText='Campo obligatorio'
						/>

						<TextField
							sx={{ gridArea: 'primer' }}
							disabled={editarCliente}
							value={cliente.primer_nombre}
							label='Primer Nombre'
							name='primer_nombre'
							onChange={actualizarCliente}
						/>

						<TextField
							sx={{ gridArea: 'segundo' }}
							disabled={editarCliente}
							value={cliente.segundo_nombre}
							label='Segundo Nombre'
							name='segundo_nombre'
							onChange={actualizarCliente}
						/>
						<TextField
							sx={{ gridArea: 'paterno' }}
							required
							inputProps={{
								pattern: '[a-zA-Z]+',
								title: 'Solo se aceptan letras',
							}}
							disabled={editarCliente}
							value={cliente.ap_paterno}
							label='Apellido Paterno '
							name='ap_paterno'
							onChange={actualizarCliente}
							helperText='Campo obligatorio'
						/>
						<TextField
							sx={{ gridArea: 'materno' }}
							disabled={editarCliente}
							value={cliente.ap_materno}
							label='Apellido Materno'
							name='ap_materno'
							onChange={actualizarCliente}
						/>

						<TextField
							sx={{ gridArea: 'celular' }}
							disabled={editarCliente}
							value={cliente.celular}
							label='Celular'
							name='celular'
							onChange={actualizarCliente}
							helperText='Se enviarán notificaciones por SMS'
						/>

						<TextField
							sx={{ gridArea: 'correo' }}
							disabled={editarCliente}
							value={cliente.correo}
							label='Correo'
							name='correo'
							onChange={actualizarCliente}
							helperText='Se enviarán notificaciones por correo'
						/>

						<TextField
							sx={{ gridArea: 'nit' }}
							disabled={editarCliente}
							value={cliente.nit}
							label='NIT'
							name='nit'
							onChange={actualizarCliente}
						/>

						<TextField
							sx={{ gridArea: 'razon' }}
							disabled={editarCliente}
							value={cliente.razon_social}
							label='Razón Social'
							name='razon_social'
							onChange={actualizarCliente}
						/>
					</Grid>
					{editarCliente === false && (
						<Button
							fullWidth
							sx={{ margin: 'auto' }}
							onClick={() => {
								guardarCliente();
							}}
							variant='contained'
						>
							Guardar
						</Button>
					)}
				</AccordionDetails>
			</Accordion>
		)
	);
}
