import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;

const token = JSON.parse(localStorage.getItem('colaborador'));

export const registrarCambioProducto = async ({ id_otg, id_variacion }) => {
	return await axios.post(
		`${API_URL}v1/cambios_productos/registrar`,
		{ id_otg, id_variacion },
		{
			headers: {
				Authorization: 'Bearer ' + token,
			},
		}
	);
};
