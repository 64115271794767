import React from 'react';

import {
	makeStyles,
	Modal,
	Grid,
	CircularProgress,
	Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
	modal: {
		display: 'flex',
		margin: 'auto 1rem',
	},

	modal__card: {
		outline: 'none',
		borderRadius: '10px',
		background: '#f2f2f2',
		margin: 'auto',
		display: 'flex',
		flexDirection: 'column',
		padding: '1rem ',
	},

	modal__titulo: {
		fontFamily: 'Gotham-Bold',
		margin: '.5rem auto',
	},
});

export default function ModalEspere(props) {
	const classes = useStyles();

	return (
		<Modal open={props.mostrar} className={classes.modal}>
			<Grid className={classes.modal__card}>
				<Typography variant='body1' className={classes.modal__titulo}>
					{props.titulo}
				</Typography>
				<CircularProgress style={{ margin: 'auto' }}></CircularProgress>
			</Grid>
		</Modal>
	);
}
