import { tiemposEstados } from '../../../services/reportes.service';

import _ from 'lodash';

import moment from 'moment';

const capitalizeString = (string) =>
	string[0].toUpperCase() + string.slice(1).toLowerCase();

export const dataTiemposPorEstados = (rango) => {
	return tiemposEstados(rango)
		.then((response) => {
			const datosFilas = [];

			const _otgs = _.groupBy(response.data, 'id_otg');

			const otgs = Object.values(_otgs);

			for (let j = 0; j < otgs.length; j++) {
				let estado = otgs[j];

				for (let i = 0; i < estado.length; i++) {
					let start = moment(estado[i].updatedAt);
					let end = '';

					if (estado[i + 1]) {
						end = moment(estado[i + 1].updatedAt);
					} else {
						if (estado[i].Estado.nombre_estado === 'finalizado') {
							end = start;
						} else {
							end = moment(Date.now());
						}
					}

					let fila = {
						'Número de otg': estado[i].id_otg,
						estado: `${estado[i].Estado.order}. ${capitalizeString(
							estado[i].Estado.nombre_estado
						)}`,
						Inicio: start.format('DD/MM/YYYY'),
						Fin: end.format('DD/MM/YYYY'),
						Tiempo: end.diff(start, 'days', true),
					};

					datosFilas.push(fila);
				}
			}

			return datosFilas;
		})
		.catch((err) => {
			console.log(err);
		});
};
