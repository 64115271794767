import { useState, useEffect } from 'react';
import { useSnackbar } from '../../context/SnackBarContext';
import { Paper, Button, Grid, Typography, TextField, Box } from '@mui/material';
import { crearProveedores } from '../../services/proveedores.service';


export default function RegistroProveedor() {
    const { showSnackbar } = useSnackbar();

    const handleClickSnackbar = (message, severity) => {
        showSnackbar({
            message,
            severity,
        });
    };

    const [datosProveedor, setDatosProveedor] = useState({
        primer_nombre: '',
        segundo_nombre: '',
        ap_paterno: '',
        ap_materno: '',
        razon_social: '',
        nit: '',
        celular: '',
        direccion: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDatosProveedor({
            ...datosProveedor,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        crearProveedores(datosProveedor)
            .then((response) => {
                handleClickSnackbar('Proveedor registrado exitosamente', 'success');
                // Reset form fields
                setDatosProveedor({
                    primer_nombre: '',
                    segundo_nombre: '',
                    ap_paterno: '',
                    ap_materno: '',
                    razon_social: '',
                    nit: '',
                    celular: '',
                    direccion: '',
                });
            })
            .catch((err) => {
                handleClickSnackbar(
                    `Error al crear el proveedor. ${err.response.data.message}`,
                    'error'
                );
            });
    };

    return (
        <Grid container justifyContent='center' sx={{ padding: 3 }}>
    <Paper elevation={3} sx={{ padding: 6 }}>
        <Typography variant='h6' color='initial' align='center'>
            Registro de proveedor
        </Typography>

        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    name='primer_nombre'
                    margin='dense'
                    fullWidth
                    onChange={handleChange}
                    value={datosProveedor.primer_nombre}
                    label='Primer Nombre'
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    name='segundo_nombre'
                    margin='dense'
                    fullWidth
                    onChange={handleChange}
                    value={datosProveedor.segundo_nombre}
                    label='Segundo Nombre'
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    name='ap_paterno'
                    margin='dense'
                    fullWidth
                    onChange={handleChange}
                    value={datosProveedor.ap_paterno}
                    label='Apellido Paterno'
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    name='ap_materno'
                    margin='dense'
                    fullWidth
                    onChange={handleChange}
                    value={datosProveedor.ap_materno}
                    label='Apellido Materno'
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    name='razon_social'
                    margin='dense'
                    fullWidth
                    onChange={handleChange}
                    value={datosProveedor.razon_social}
                    label='Razón Social'
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    name='nit'
                    margin='dense'
                    fullWidth
                    onChange={handleChange}
                    value={datosProveedor.nit}
                    label='NIT'
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    name='celular'
                    margin='dense'
                    fullWidth
                    onChange={handleChange}
                    value={datosProveedor.celular}
                    label='Celular'
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    name='direccion'
                    margin='dense'
                    fullWidth
                    onChange={handleChange}
                    value={datosProveedor.direccion}
                    label='Dirección'
                />
            </Grid>
        </Grid>

        <Grid container justifyContent='center'>
            <Button
                variant='contained'
                color='primary'
                style={{ margin: '1rem auto' }}
                onClick={handleSubmit}
            >
                Registrar
            </Button>
        </Grid>
    </Paper>
</Grid>

    );
}
