import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

export const crearCliente = (datosCliente) => {
	return axios
		.post(`${API_URL}clientes`, datosCliente, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const buscarCliente = (ci) => {
	return axios
		.post(
			`${API_URL}buscar_cliente`,
			{
				ci: ci,
			},
			{
				headers: {
					authorization: 'Bearer ' + token,
				},
			}
		)
		.then((response) => {
			return response;
		});
};

export const updateCliente = (datosCliente) => {
	return axios
		.put(`${API_URL}update_cliente`, datosCliente, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};
