import React, { useState } from 'react';

import {
	Grid,
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import { addCost, otgServices } from '../../../services/servicios.service';
import { cambiarUnEstado } from '../../../services/otg.service';

export default function CostDialog({
	open,
	onClose,
	idService,
	id_colaborador,
}) {
	const [costData, setCostData] = useState({
		costo: 0,
		nro_factura_recibo: 0,
		costo_transporte_proveedor: 0,
	});

	const saveData = async () => {
		try {
			const { data: updatedService } = await addCost({
				...costData,
				id_servicio: idService,
			});

			const { data: dataOtgServices } = await otgServices(
				updatedService.data.id_otg
			);

			const calidad = await dataOtgServices.data.some((service) => {
				return service.estado === 'pendiente por calidad';
			});

			const reparacion = await dataOtgServices.data.some((service) => {
				return service.estado === 'en reparación';
			});

			if (calidad) {
				await cambiarUnEstado({
					id_otg: updatedService.data.id_otg,
					id_estado: 3,
					id_colaborador: id_colaborador,
				});
			}
			if (reparacion) {
				await cambiarUnEstado({
					id_otg: updatedService.data.id_otg,
					id_estado: 4,
					id_colaborador: id_colaborador,
				});
			}

			onClose();
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Ingrese los siguientes datos:</DialogTitle>
			<DialogContent>
				<Grid container direction='column'>
					<TextField
						margin='dense'
						name='costo'
						label='Costo proveedor'
						type='number'
						onChange={(e) => {
							setCostData({ ...costData, costo: e.target.value });
						}}
					/>
					<TextField
						margin='dense'
						name='costo_transporte_proveedor'
						label='Costo  transporte'
						type='number'
						onChange={(e) => {
							setCostData({
								...costData,
								costo_transporte_proveedor: e.target.value,
							});
						}}
					/>
					<TextField
						margin='dense'
						name='nro_factura_recibo'
						label='Nro factura/recibo'
						type='number'
						onChange={(e) => {
							setCostData({
								...costData,
								nro_factura_recibo: e.target.value,
							});
						}}
					/>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						onClose();
					}}
					variant='outlined'
					sx={{ backgroundColor: 'red', color: 'white' }}
				>
					Cancelar
				</Button>
				<Button onClick={saveData} variant='contained'>
					Confirmar
				</Button>
			</DialogActions>
		</Dialog>
	);
}
