import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DialogExpirado(props) {
	const { open, SetSessionExpirada } = props;

	const handleClose = () => {
		SetSessionExpirada(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle id='alert-dialog-title'>La sesión ha expirado</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>
					Ingrese nuevamente por favor
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} autoFocus sx={{ backgroundColor: '#00a082', color: '#ffffff' }}>
					Aceptar
				</Button>
			</DialogActions>
		</Dialog>
	);
}

