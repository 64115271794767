import React, { createContext, useContext, useState } from 'react';

import { Snackbar, Alert } from '@mui/material';

const SnackbarContext = createContext();

export const useSnackbar = () => {
	return useContext(SnackbarContext);
};

export const SnackbarProvider = ({ children }) => {
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [snackbarSeverity, setSnackbarSeverity] = useState('info');

	const showSnackbar = ({ message, severity }) => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
	};

	const closeSnackbar = () => {
		setSnackbarOpen(false);
		setSnackbarMessage('');
	};

	const value = {
		showSnackbar,
		closeSnackbar,
	};

	return (
		<SnackbarContext.Provider value={value}>
			{children}
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={3000}
				onClose={closeSnackbar}
				anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
			>
				<Alert onClose={closeSnackbar} severity={snackbarSeverity}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</SnackbarContext.Provider>
	);
};

