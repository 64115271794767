import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;

const token = JSON.parse(localStorage.getItem('colaborador'));

export const findCiudades = async () => {
	return await axios
		.get(`${API_URL}find_ciudades`, {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};
