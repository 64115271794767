import React, { useState, useEffect, useRef } from 'react';

import { nanoid } from 'nanoid';

import {
	Typography,
	Grid,
	Button,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableContainer,
	AccordionSummary,
	AccordionDetails,
	Paper,
	Accordion,
} from '@mui/material';

import { findAllOtgsOrder } from '../../services/otg.service';

import { verificar } from '../../services/auth.service';

import ModalEspere from '../../components/ModalEspere.component';
import { useHistory } from 'react-router-dom';
import ModalFacturado from './components/ModalFacturado';

import CostDialog from './components/CostDialog';

export default function Reparaciones() {
	const history = useHistory();

	const [idService, setIdService] = useState(0);

	const [datosOtgs, setDatosOtgs] = useState([]);

	const [modalEspere, setModalEspere] = useState(false);

	const estadoOtg = useRef({
		id_otg: '',
		id_estado: 5,
		id_colaborador: '',
	});

	const [obtenerDatos, setObtenerDatos] = useState(true);
	const sessionToken = JSON.parse(localStorage.getItem('colaborador'));

	const [circularProgress, setCircularProgress] = useState(true);

	const [otgSeleccionada, setOtgSeleccionada] = useState([]);

	const [modalFacturado, setModalFacturado] = useState(false);

	useEffect(() => {
		verificar(sessionToken.token)
			.then((response) => {
				estadoOtg.current = {
					...estadoOtg.current,
					id_colaborador: response.data.id_colaborador,
				};
			})
			.catch((err) => {
				history.go(0);
			});

		if (obtenerDatos) {
			findAllOtgsOrder({ estado: 4 })
				.then((response) => {
					setDatosOtgs(response.data);

					setObtenerDatos(false);
					setCircularProgress(false);
					setModalEspere(false);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [obtenerDatos, sessionToken.token, history]);

	const handleOpenDialog = (_idService) => {
		setIdService(_idService);
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setObtenerDatos(!obtenerDatos);
	};

	const goToOtg = (id_externo) => {
		history.push(`/otg/${id_externo}`);
	};

	const [openDialog, setOpenDialog] = useState(false);

	return (
		<>
			<CostDialog
				open={openDialog}
				onClose={handleCloseDialog}
				idService={idService}
				id_colaborador={estadoOtg.current.id_colaborador}
			/>

			<ModalFacturado
				open={modalFacturado}
				close={(value) => {
					setModalFacturado(value);
				}}
				otgSeleccionada={otgSeleccionada}
				id_colaborador={estadoOtg.current.id_colaborador}
				updateComponent={(value) => {
					setObtenerDatos(value);
				}}
			/>

			<ModalEspere
				mostrar={modalEspere}
				titulo='Registrando, por favor espere'
			/>

			<Typography align='center' variant='h5' paragraph={true}>
				OTGs en espera de regreso de proveedor
			</Typography>

			{circularProgress ? (
				<Grid container>
					<CircularProgress style={{ margin: 'auto' }}></CircularProgress>
				</Grid>
			) : (
				datosOtgs.map((otg) => {
					return (
						<Accordion key={nanoid()} expanded>
							<AccordionSummary>
								<Grid container>
									<Grid item xs={12} md={6}>
										<Typography
											onClick={() => {
												goToOtg(otg.id_externo);
											}}
										>
											N° OTG <span>{otg.id_otg}</span>
										</Typography>
										<Typography variant='body2' color='initial'>
											Nombre de producto:{' '}
											<span>{otg.Variacion.nombre_producto}</span>
										</Typography>
									</Grid>
									<Grid item xs={12} md={6}>
										{otg.Servicios.every(
											(servicio) => servicio.estado === 'atendido en calidad'
										) ? (
											<Button
												onClick={() => {
													setOtgSeleccionada(otg);
													setModalFacturado(true);
												}}
												variant='contained'
												color='primary'
											>
												Precio facturado
											</Button>
										) : null}
									</Grid>
								</Grid>
							</AccordionSummary>

							<AccordionDetails>
								<TableContainer component={Paper}>
									<Table aria-label='simple table'>
										<TableHead>
											<TableRow>
												<TableCell>Tipo de falla</TableCell>
												<TableCell>Detalle</TableCell>
												<TableCell>Acciones</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{otg.Servicios.map((servicio) =>
												servicio.estado === 'en reparación' ? (
													<TableRow key={nanoid()}>
														<TableCell>{servicio.tipo_servicio}</TableCell>
														<TableCell>{servicio.Falla.nombre_falla}</TableCell>

														<TableCell>
															<Button
																onClick={() => {
																	handleOpenDialog(servicio.id_servicio);
																}}
																variant='contained'
																color='primary'
															>
																Costo
															</Button>
														</TableCell>
													</TableRow>
												) : null
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</AccordionDetails>
						</Accordion>
					);
				})
			)}
		</>
	);
}
