import React, { useState, useEffect } from 'react';
import moment from 'moment';

import {
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Table,
	TableRow,
	TableCell,
	TableBody,
	Grid,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { findHistorico } from '../../../services/historico.service';

export default function TrackingEstados({ id_otg }) {
	const calcularDiasTranscurridos = () => {
		const estadoInicial = moment(datosHistoricos[0].createdAt);

		const _estadoFinal = () => {
			const lastHistorical = datosHistoricos[datosHistoricos.length - 1];

			if (lastHistorical.id_estado === 8 || lastHistorical.id_estado === 12) {
				return moment(datosHistoricos[datosHistoricos.length - 1].updatedAt);
			} else {
				return new Date();
			}
		};
		const estadoFinal = moment(_estadoFinal());

		return estadoFinal.diff(estadoInicial, 'days');
	};

	const [datosHistoricos, setDatosHistoricos] = useState([]);

	useEffect(() => {
		try {
			const getHistoricals = async () => {
				const { data } = await findHistorico(id_otg);
				setDatosHistoricos(data);
			};
			getHistoricals();
		} catch (err) {
			console.log(err);
		}
	}, [id_otg]);

	if (datosHistoricos.length > 0) {
		const diasTranscurridos = calcularDiasTranscurridos();

		return (
			<>
				<Typography align='center' variant='h6'>
					Tracking de estados
				</Typography>
				<Typography align='center'>
					Tiempo de atención a la fecha: {diasTranscurridos} días
				</Typography>

				<Accordion
					sx={{
						paddingX: { sm: '1em', lg: '10rem' },
					}}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell size='small' sx={{ border: 'none' }}>
										Estado Actual
									</TableCell>
									<TableCell
										size='small'
										sx={{ border: 'none', textTransform: 'capitalize' }}
									>
										{`${
											datosHistoricos[datosHistoricos.length - 1].Estado.order
										}. ${
											datosHistoricos[datosHistoricos.length - 1].Estado
												.nombre_estado
										}`}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell size='small' sx={{ border: 'none' }}>
										Fecha de registro:
									</TableCell>
									<TableCell size='small' sx={{ border: 'none' }}>
										{moment(datosHistoricos[0].updatedAt).format('DD-MM-YYYY')}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell size='small' sx={{ border: 'none' }}>
										Último cambio:{' '}
									</TableCell>
									<TableCell size='small' sx={{ border: 'none' }}>
										{moment(
											datosHistoricos[datosHistoricos.length - 1].updatedAt
										).format('DD-MM-YYYY')}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</AccordionSummary>
					<AccordionDetails>
						{datosHistoricos.map((fila, i) => {
							return (
								<Accordion key={i}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography>{fila.Estado.nombre_estado} </Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid container justifyContent='space-between'>
											<Typography variant='subtitle2' color='initial'>
												Registrado por:
											</Typography>
											<Typography variant='subtitle2' color='initial'>
												{fila.Colaborador.usuario}
											</Typography>
										</Grid>
										<Grid container justifyContent='space-between'>
											<Typography variant='subtitle2' color='initial'>
												Razón de cambio
											</Typography>
											<Typography variant='subtitle2' color='initial'>
												{fila.razon_cambio}
											</Typography>
										</Grid>
										<Grid container justifyContent='space-between'>
											<Typography variant='subtitle2' color='initial'>
												Fecha y hora:
											</Typography>
											<Typography variant='subtitle2' color='initial'>
												{moment(fila.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
											</Typography>
										</Grid>
									</AccordionDetails>
								</Accordion>
							);
						})}
					</AccordionDetails>
				</Accordion>
			</>
		);
	} else {
		return <Typography align='center'>Cargando componente...</Typography>;
	}
}
