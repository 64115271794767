import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#231f20',
		},
		secondary: {
			main: '#ef4135',
		},
	},

	typography: {
		fontFamily: 'Gotham-Book',
	},

	components: {
		MuiButton: {
            styleOverrides: {
                root: {
                    margin: '.5rem',
                    color: '#ffffff', // Cambia esto al color que desees para el texto del botón
					fontWeight: 'bold',
                },
            },
        },
		MuiToolbar: {
			styleOverrides: {
				root: {
					backgroundSize: '30%',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
				},
			},
		},
	},

	// components: {
	// 	MuiCssBaseline: {
	// 		styleOverrides: `
	//       @font-face {
	//         font-family: 'Gotham';
	//         font-style: normal;
	//         font-display: swap;
	//         font-weight: 400;
	//         src: local('Gotham'), local('Gotham'), url(${Gotham}) format('otf');
	//         unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
	//       }
	//     `,
	// 	},
	// },
});

export default theme;
