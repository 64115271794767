import React, { useEffect, useRef, useState } from 'react';
import { useSnackbar } from '../context/SnackBarContext';

import { useLocation, useHistory } from 'react-router-dom';

import Barcode from 'react-barcode';

import { useReactToPrint } from 'react-to-print';

import { Typography, Grid, Button } from '@mui/material';

import { styled } from '@mui/material/styles';

import ModalEspere from '../components/ModalEspere.component';

import { findOneTicket } from '../services/ticket.service';
import { verificar } from '../services/auth.service';

const Spancustom = styled('span')`
	font-family: Gotham-Medium;
	text-transform: capitalize;
`;

export default function Ticket() {
	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const datosRecibidos = useLocation();
	const history = useHistory();

	const [habilitarImpresion, setHabilitarImpresion] = useState(false);

	const id_otg = datosRecibidos.state.id_otg;
	const componentRef = useRef();

	const [datosTicket, setDatosTicket] = useState();
	const [modalEspere, setModalEspere] = useState(true);
	const [idCliente, setIdCliente] = useState('');

	const [datosSession, setDatosSession] = useState({});

	useEffect(() => {
		const sessionToken = JSON.parse(localStorage.getItem('colaborador'));
		verificar(sessionToken.token)
			.then((response) => {
				setDatosSession(response.data);
			})
			.catch((err) => {
				history.go(0);
			});
	}, [history]);

	useEffect(() => {
		findOneTicket(id_otg)
			.then((response) => {
				const datosOtg = response.data;

				setIdCliente(response.data.Cliente.id_cliente);

				setDatosTicket({
					id_otg: datosOtg.id_otg.toString(),
					createdAt: datosOtg.createdAt,
					nombre_producto: datosOtg.Variacion.nombre_producto,
					Servicios: datosOtg.Servicios,
					nombre_cliente: `${datosOtg.Cliente.primer_nombre} ${datosOtg.Cliente.segundo_nombre} ${datosOtg.Cliente.ap_paterno} ${datosOtg.Cliente.ap_materno}`,
					ubi_origen: datosOtg.ubiOrigen.nombre_ubicacion,
				});

				setModalEspere(false);
				setHabilitarImpresion(true);
			})
			.catch((err) => {
				setModalEspere(false);
				setHabilitarImpresion(false);

				handleClickSnackbar('Error al momento de generar el ticket', 'error');
			});
	}, [id_otg]);

	let contador = 0;
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		onAfterPrint: () => {
			contador++;
			if (contador < 3) {
				handlePrint();
			} else {
				contador = 0;
			}
		},
	});

	// const classes = useStyles();

	if (!datosTicket)
		return (
			<ModalEspere
				titulo='Generando Ticket, espere por favor'
				mostrar={modalEspere}
			/>
		);

	return (
		<>
			<Grid
				ref={componentRef}
				sx={{ padding: '0 2rem', justifyContent: 'center' }}
				container
			>
				<Typography variant='body1'>Codigo de OTG</Typography>
				<Grid container justifyContent={'center'}>
					<Barcode value={id_otg.toString()} />
				</Grid>
				<Grid
					container
					direction={'column'}
					sx={{ border: '1px dashed black' }}
				>
					<Typography variant='body2'>
						Producto: <Spancustom>{datosTicket.nombre_producto}</Spancustom>
					</Typography>

					<Typography variant='body2'>
						Cliente: <Spancustom>{datosTicket.nombre_cliente}</Spancustom>
					</Typography>
					<Typography variant='body2'>
						Origen: <Spancustom>{datosTicket.ubi_origen}</Spancustom>
					</Typography>
					<Typography variant='body2'>
						Registrado el: <Spancustom>{datosTicket.createdAt}</Spancustom>
					</Typography>
					{datosTicket.Servicios.map((servicio, index) => {
						return (
							<Grid
								key={index}
								sx={{
									borderTop: '1px dashed black',
								}}
							>
								<Typography variant='body2'>
									Falla {index + 1}:{' '}
									<Spancustom>{servicio.Falla.nombre_falla}</Spancustom>
								</Typography>
								<Typography variant='body2'>
									Registrado como:{' '}
									<Spancustom>{servicio.tipo_servicio}</Spancustom>
								</Typography>
							</Grid>
						);
					})}
				</Grid>
				<Grid container direction='column'>
					<Typography
						align='center'
						sx={{
							fontFamily: 'Gotham-Medium',
							textTransform: 'capitalize',
							marginTop: '3rem',
							borderTop: '1px solid black',
						}}
						variant='body2'
					>
						Recibí el producto Conforme
					</Typography>
					<Typography align='center' sx={{ fontSize: '.5rem' }}>
						Una vez concluido el proceso de reparación se le notificará mediante
						correo y SMS.
					</Typography>
				</Grid>
			</Grid>

			<Grid container justifyContent={'center'} direction='column'>
				<Button
					disabled={!habilitarImpresion}
					onClick={handlePrint}
					variant='contained'
					color='primary'
				>
					Imprimir
				</Button>

				{datosSession.id_tipo === 1 ||
				datosSession.id_tipo === 3 ||
				datosSession.id_tipo === 5 ? (
					<Button
						disabled={!habilitarImpresion}
						onClick={() => {
							history.push({
								pathname: '/registrar',
								state: {
									idCliente: idCliente,
								},
							});
						}}
						variant='text'
						sx={{ backgroundColor: 'red', color: 'white' }}
					>
						Nueva OTG
					</Button>
				) : (
					''
				)}
			</Grid>
		</>
	);
}
