import React, { useEffect, useState, useRef, useContext } from 'react';
import { useSnackbar } from '../../context/SnackBarContext';

import { useParams, useHistory } from 'react-router';

import { UserContext } from '../../context/UserContext';

import {
	Grid,
	Typography,
	Input,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
	Modal,
	TextField,
	Checkbox,
	FormControlLabel,
	IconButton,
} from '@mui/material';

import { ExpandMore, DoneAllRounded, Edit } from '@material-ui/icons';

import {
	updateTienda,
	updateOtrosDatos,
	findOneOtg,
} from '../../services/otg.service';

import Cliente from './components/Cliente.component';
import Producto from './components/Producto.component';
import Servicio from './components/Servicio.component';
import ModalCambio from './components/ModalCambio';
import TrackingEstados from './components/TrackingEstados.component';
import CambioEstados from './components/CambioEstados';

export default function Otg() {
	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};
	const { idExterno } = useParams();

	const history = useHistory();

	const [mostrar, setMostrar] = useState(false);

	const [modalCambio, setModalCambio] = useState(false);

	const datosSession = useContext(UserContext);

	const [datosOtg, setDatosOtg] = useState();

	const datosEstado = useRef({
		id_otg: '',
		id_estado: '',
		id_colaborador: '',
		razon_cambio: '',
	});

	const [datosColaborador, setDatosColaborador] = useState({});

	const [editarOtros, setEditarOtros] = useState(true);

	const id_ciudad = useRef('');

	// EDICION DE TIENDA
	const [editarTienda, setEditarTienda] = useState(true);

	const guardarTienda = () => {
		const datosTienda = {
			id_otg: datosOtg.id_otg,
			ubi_origen: datosOtg.origen,
			ubi_destino: datosOtg.destino,
			responsable: datosOtg.responsable,
		};

		updateTienda(datosTienda)
			.then(() => {
				handleClickSnackbar('Edición de ubicaciones exitoso', 'success');

				setEditarTienda(!editarTienda);
			})
			.catch((err) => {
				handleClickSnackbar(
					`Error al actualizar los datos de la tienda.${err.response.data.message}`,
					'error'
				);
			});
	};

	// INICIO REQUIERE TRANSPORTE

	const guardarOtrosDatos = () => {
		const datosOtrosDatos = {
			id_otg: datosOtg.id_otg,
			req_transporte: datosOtg.req_transporte,
			fecha_compra: datosOtg.fecha_compra,
		};
		updateOtrosDatos(datosOtrosDatos)
			.then(() => {
				handleClickSnackbar('Cambio exitoso', 'success');
				setEditarOtros(!editarOtros);
			})
			.catch((err) => {
				handleClickSnackbar(err.response.data.message, 'error');

				setEditarOtros(!editarOtros);
			});
	};

	// FIN REQUIERE TRANSPORTE

	const getResponse = (response) => {
		handleClickSnackbar(response.message, response.type);
	};

	useEffect(() => {
		datosEstado.current.id_colaborador = datosSession.id_colaborador;
		id_ciudad.current = datosSession.id_ciudad;
		setDatosColaborador(datosSession);
	}, [datosSession]);

	useEffect(() => {
		const fetchOtgData = async (idExterno) => {
			try {
				const response = await findOneOtg(idExterno);

				// setNuevoEstado(response.data.id_estado);
				const { id_otg, id_estado } = response.data;

				datosEstado.current.id_estado = id_estado;
				datosEstado.current.id_otg = id_otg;

				setDatosOtg(response.data);
			} catch (err) {
				console.error(err);
			}
		};

		fetchOtgData(idExterno);
	}, [idExterno, history, modalCambio]);

	const modal = (
		<Modal
			onClose={() => {
				setMostrar(false);
			}}
			open={mostrar}
		>
			<div>
				<Typography variant='h6'>Entrega registrada</Typography>
				<DoneAllRounded style={{ minWidth: '100%', minHeight: '100px' }} />
				<Grid container justifyContent='space-around'>
					<Button
						onClick={() => {
							history.push('/');
						}}
						variant='contained'
						color='primary'
					>
						Aceptar
					</Button>
				</Grid>
			</div>
		</Modal>
	);

	if (!datosOtg) return 'cargando...';

	return (
		<>
			{modal}

			<ModalCambio
				open={modalCambio}
				close={(value) => {
					setModalCambio(value);
				}}
				id_otg={datosOtg.id_otg}
				id_colaborador={datosColaborador.id_colaborador}
			/>

			<Grid container justifyContent='center'>
				<Typography variant='h5' color='initial'>
					Número de OTG: {datosOtg.id_otg}
				</Typography>
			</Grid>

			{/* INFORMACION DEL CLIENTE */}
			<Cliente cliente={datosOtg.Cliente} getResponse={getResponse} />

			{/* INFORMACION DEL PRODUCTO */}
			<Producto producto={datosOtg.Variacion} />

			{/* INFORMACION DE SERVICIO */}
			<Servicio
				SetDatosOtg={(servicios) => {
					setDatosOtg({ ...datosOtg, servicios });
				}}
				servicios={datosOtg.Servicios}
				datosColaborador={datosColaborador}
			/>

			{/* OTROS DATOS */}
			<Accordion>
				<Grid container justifyContent='space-around' alignItems='center'>
					<Typography variant='subtitle1'>
						Otros datos de la OTG
						{(datosColaborador.id_tipo === 1 ||
							datosColaborador.id_tipo === 3 ||
							datosColaborador.id_tipo === 5) && (
							<IconButton
								size='small'
								onClick={() => {
									setEditarOtros(!editarOtros);
								}}
							>
								<Edit />
							</IconButton>
						)}
					</Typography>
					<AccordionSummary expandIcon={<ExpandMore />}></AccordionSummary>
				</Grid>

				<AccordionDetails
					sx={{
						paddingX: { sm: '1em', lg: '10rem' },
					}}
				>
					<Grid container justifyContent='center'>
						<TextField
							margin='dense'
							fullWidth
							label='Responsable'
							value={datosOtg.responsable || ''}
							disabled={editarTienda}
							onChange={(e) => {
								setDatosOtg({ ...datosOtg, responsable: e.target.value });
							}}
						/>
						<TextField
							margin='dense'
							fullWidth
							label='Observaciones'
							value={datosOtg.observaciones || ''}
							disabled={editarTienda}
							onChange={(e) => {
								setDatosOtg({ ...datosOtg, observaciones: e.target.value });
							}}
						/>

						<Grid container direction='row' justifyContent='space-between'>
							<Typography variant='caption'>Fecha de compra:</Typography>

							<Input
								type='date'
								onChange={(e) => {
									setDatosOtg({
										...datosOtg,
										fecha_compra: e.target.value,
									});
								}}
								disabled={editarOtros}
								value={datosOtg.fecha_compra || ''}
							/>
						</Grid>
						<FormControlLabel
							disabled={editarOtros}
							label='Requiere transporte'
							labelPlacement='start'
							control={
								<Checkbox
									checked={datosOtg.req_transporte}
									onChange={(e) => {
										setDatosOtg({
											...datosOtg,
											req_transporte: e.target.checked,
										});
									}}
								/>
							}
						/>
						{!editarOtros && (
							<Button fullWidth onClick={guardarOtrosDatos} variant='contained'>
								Guardar
							</Button>
						)}
						{/* Usado en un antiguio compomente, puede que se requiera en un futuro */}
						{editarTienda === false && (
							<Grid container>
								<Button
									onClick={guardarTienda}
									variant='contained'
									color='primary'
								>
									Guardar
								</Button>
							</Grid>
						)}
					</Grid>
				</AccordionDetails>
			</Accordion>

			{/* Botón realizar cambio de producto */}
			{datosOtg.id_estado === 9 && datosColaborador.id_tipo === 1 && (
				<Grid container justifyContent='center'>
					<Button
						variant='contained'
						style={{ margin: '1rem auto' }}
						onClick={() => {
							setModalCambio(true);
						}}
					>
						Realizar cambio de producto
					</Button>
				</Grid>
			)}

			<CambioEstados
				estadoActual={datosOtg.id_estado}
				id_otg={datosOtg.id_otg}
			/>

			<TrackingEstados id_otg={datosOtg.id_otg} />

			<Grid container justifyContent='center'>
				<Button
					onClick={() => {
						history.push({
							pathname: '/ticket',
							state: {
								id_otg: datosOtg.id_otg,
							},
						});
					}}
					variant='contained'
					color='primary'
				>
					Ver ticket de OTG
				</Button>
			</Grid>
			
		</>
	);
}
