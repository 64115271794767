import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

const crearOtg = async (datosOtg) => {
	return await axios
		.post(`${API_URL}create-otg-v2`, datosOtg, {
			headers: {
				authorization: 'Bearer ' + token.token,
			},
		})
		.then((responseOtg) => {
			return responseOtg;
		});
};

export const findAllOtgs = async () => {
	const _datosOtgs = await axios.get(`${API_URL}otgs`, {
		headers: {
			authorization: 'Bearer ' + token.token,
		},
	});
	return _datosOtgs;
};

export const findExterno = async (id_otg) => {
	const response = await axios.post(
		`${API_URL}find_externo`,
		{
			id_otg: id_otg,
		},
		{
			headers: {
				Authorization: 'Bearer ' + token.token,
			},
		}
	);
	return response;
};

export const findOneOtg = async (idExterno) => {
	const response = await axios.post(
		`${API_URL}find_otg`,
		{
			id_externo: idExterno,
		},
		{
			headers: {
				Authorization: 'Bearer ' + token.token,
			},
		}
	);
	return response;
};

export const findAllOtgsOrder = async (estado) => {
	return await axios.post(`${API_URL}otgs_order_tiendas`, estado, {
		headers: {
			Authorization: 'Bearer ' + token.token,
		},
	});
};

export const cambiarEstado = (arrayOtgs) => {
	return new Promise((resolve, rejected) => {
		arrayOtgs.forEach((otg) => {
			return axios
				.post(
					`${API_URL}cambiar_estado`,
					{
						id_otg: otg.id_otg,
						id_estado: otg.id_estado,
						id_colaborador: otg.id_colaborador,
						razon_cambio: otg.razon_cambio,
						tipo_transporte: otg.tipo_transporte,
					},
					{
						headers: {
							Authorization: 'Bearer ' + token.token,
						},
					}
				)
				.then((response) => {
					return response;
				})
				.catch((error) => {
					console.log(error.response);
				});
		});
		resolve(true);
	});
};

export const cambiarUnEstado = async (datosEstado) => {
	return await axios.post(`${API_URL}cambiar_estado`, datosEstado, {
		headers: {
			Authorization: 'Bearer ' + token.token,
		},
	});
};

export const enviarImagenes = (imagen) => {
	return axios
		.post(`${API_URL}enviarImagen`, imagen, {
			headers: {
				Authorization: 'Bearer ' + token.token,
			},
		})
		.then((res) => {
			return res;
		});
};

export const findAllOtgsTransportadora = () => {
	return axios
		.get(`${API_URL}findall_otgs_transportadora`, {
			headers: {
				Authorization: 'Bearer ' + token.token,
			},
		})
		.then((res) => {
			return res;
		});
};

export const findAllForDestino = async (datos) => {
	return await axios
		.post(`${API_URL}find_all_for_destino`, datos, {
			headers: {
				Authorization: 'Bearer ' + token.token,
			},
		})
		.then((res) => {
			return res;
		});
};

export const cambiarUbicaciones = (datos) => {
	return axios
		.post(`${API_URL}cambiar_ubicaciones`, datos, {
			headers: {
				Authorization: 'Bearer ' + token.token,
			},
		})
		.then((res) => {
			return res;
		});
};

export const findOneById = (datos) => {
	return axios
		.post(`${API_URL}find_one_by_id`, datos, {
			headers: {
				Authorization: 'Bearer ' + token.token,
			},
		})
		.then((res) => {
			return res;
		});
};

export const updateVariacion = (datos) => {
	return axios
		.put(`${API_URL}update_variacion`, datos, {
			headers: {
				Authorization: 'Bearer ' + token.token,
			},
		})
		.then((res) => {
			return res;
		});
};

export const updateTienda = (datos) => {
	return axios
		.put(`${API_URL}update_tienda`, datos, {
			headers: {
				Authorization: 'Bearer ' + token.token,
			},
		})
		.then((res) => {
			return res;
		});
};

export const updateOtrosDatos = (datos) => {
	return axios
		.put(`${API_URL}update_otros_datos`, datos, {
			headers: {
				Authorization: 'Bearer ' + token.token,
			},
		})
		.then((res) => {
			return res;
		});
};

export const otgsRegistradasReqTrans = async () => {
	return await axios.get(`${API_URL}otgs_registradas_req_trans`, {
		headers: {
			Authorization: 'Bearer ' + token.token,
		},
	});
};

export const findOtgServicios = async (datos) => {
	return await axios.post(`${API_URL}find-otg-servicios`, datos, {
		headers: {
			Authorization: 'Bearer ' + token.token,
		},
	});
};

export const evaluarServicios = async (datos) => {
	return await axios.post(`${API_URL}evaluar-servicios`, datos, {
		headers: {
			Authorization: 'Bearer ' + token.token,
		},
	});
};

export const entregar = async (datos) => {
	return await axios.post(`${API_URL}v1/otgs/entregar`, datos, {
		headers: {
			Authorization: 'Bearer ' + token.token,
		},
	});
};

export const actualizar = async (datos) => {
	return await axios.put(`${API_URL}v1/otgs/actualizar`, datos, {
		headers: {
			Authorization: 'Bearer ' + token.token,
		},
	});
};

export const find = async (datos) => {
	return await axios.post(`${API_URL}v1/otgs/find`, datos, {
		headers: {
			Authorization: 'Bearer ' + token.token,
		},
	});
};

const functions = { crearOtg, findAllOtgs, findOneOtg };
export default functions;
