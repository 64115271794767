import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

export const findOtgsEstado = (id_estado) => {
	return axios
		.post(
			`${API_URL}reporte_estado`,
			{ id_estado: id_estado },
			{
				headers: {
					authorization: 'Bearer ' + token,
				},
			}
		)
		.then((response) => {
			return response;
		});
};

export const reporteFindAllOtgs = (fechas) => {
	return axios
		.post(`${API_URL}reporte_find_all_otgs`, fechas, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const reporteFindAllHistoricos = (fechas) => {
	return axios
		.post(`${API_URL}reporte_find_all_historicos`, fechas, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};
export const reporteFindAllCostoReparacion = (fechas) => {
	return axios
		.post(`${API_URL}find_all_costo_reparacion`, fechas, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const findAllOtgsPendientes = (fechas) => {
	return axios
		.post(`${API_URL}find_all_otgs_pendientes`, fechas, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const enTienda = (fechas) => {
	return axios
		.post(`${API_URL}otgs/en_tienda`, fechas, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const cambiosGarantia = async (fechas) => {
	return await axios
		.post(`${API_URL}reporte_cambios_garantia`, fechas, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const rendicionCajaChica = async (fechas) => {
	return await axios
		.post(`${API_URL}reporte_rendicion_caja_chica`, fechas, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const ejecucionSolicitudesPendientes = async (fechas) => {
	return await axios
		.post(`${API_URL}reporte_ejecucion_solicitudes_pendientes`, fechas, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const tiemposEstados = async (fechas) => {
	return await axios
		.post(`${API_URL}reporte_tiempos_estados`, fechas, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const internoReparaciones = async (fechas) => {
	return await axios
		.post(`${API_URL}reporte_interno_reparaciones`, fechas, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};
