import React from 'react';

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	Button,
} from '@mui/material';

import { direccion as API_URL } from '../../../utils/localhost';

import axios from 'axios';

export default function RecibirDialog({
	open,
	onClose,
	otgs,
	cambioEstadoExitoso,
	nextState,
	confirmTitle,
}) {
	const saveStates = async (otgs, id_estado) => {
		const sessionToken = JSON.parse(localStorage.getItem('colaborador'));

		try {
			const url = `${API_URL}v1/otgs/change_state`;
			const razon_cambio = 'Flujo normal';

			await axios.post(
				url,
				{ otgs, id_estado, razon_cambio },
				{
					headers: {
						Authorization: 'Bearer ' + sessionToken.token,
					},
				}
			);
			onClose(false);
			cambioEstadoExitoso(true);
		} catch (error) {
			console.error('Error sending POST request:', error);
		}
	};

	return (
		<Dialog onClose={onClose} open={open}>
			<div>
				<DialogTitle align='center' variant='body1'>
					{confirmTitle}
				</DialogTitle>
				<DialogContent>
					{otgs.map((otg) => (
						<Typography align='center' key={otg} variant='body1'>
							{otg}
						</Typography>
					))}
				</DialogContent>

				<DialogActions>
					<Button variant={'outlined'} sx={{ backgroundColor: 'red', color: 'white' }} onClick={onClose}>
						Cancelar
					</Button>
					<Button
						variant={'contained'}
						onClick={() => {
							saveStates(otgs, nextState);
						}}
					>
						Aceptar
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
}
