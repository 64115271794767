import React, { useEffect, useState, useContext } from 'react';
import { useSnackbar } from '../../../context/SnackBarContext';
import { UserContext } from '../../../context/UserContext';
import { createServicioTransporte } from '../../../services/servicio_transportadora.service';

import {
	Accordion,
	AccordionDetails,
	Grid,
	Typography,
	AccordionSummary,
	FormGroup,
	Checkbox,
	CircularProgress,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	DialogActions,
} from '@mui/material';

import { findTransportadorasCiudad } from '../../../services/ubicacion.service';
import { findCiudades } from '../../../services/ciudades.service';

import { styled } from '@mui/material/styles';

import { ExpandMore } from '@mui/icons-material';

import _ from 'lodash';

import RecibirDialog from './RecibirDialog';

const AccordionCustom = styled(Accordion)`
	padding: 2rem;
	margin: 1rem;
`;

export default function Section({
	title,
	query,
	nextState,
	confirmTitle,
	buttonText,
}) {
	const { showSnackbar } = useSnackbar();

	const [transportadoras, setTransportadoras] = useState([]);
	const [ciudades, setCiudades] = useState([]);

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const [selectedOtgs, setSelectedOtgs] = useState([]);

	const [dialogOpen, setDialogOpen] = useState(false);

	const [otgs, setOtgs] = useState(null);

	const handleOpenDialog = () => {
		setDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setDialogOpen(false);
	};

	const addOrRemoveElement = (selectedOtgs, element) => {
		if (!selectedOtgs.includes(element)) {
			setSelectedOtgs((prevSelectedOtgs) => [...prevSelectedOtgs, element]);
		} else {
			setSelectedOtgs((prevSelectedOtgs) =>
				prevSelectedOtgs.filter((otg) => otg !== element)
			);
		}
	};

	const [cambioEstadoExitoso, setCambioEstadoExitoso] = useState(false);

	const handleCambioEstado = () => {
		setSelectedOtgs([]);
		handleClickSnackbar('Cambio exitoso', 'success');
		setCambioEstadoExitoso(true);
		window.location.reload();
	};

	const [modalTransportadora, setModalTransportadora] = useState(false);

	const datosSession = useContext(UserContext);

	const [datosAdicionales, setDatosAdicionales] = useState({
		id_otg: '',
		numero_guia: '',
		numero_caja: '',
		costo: '',
		a_nombre: '',
		origen_transportadora: datosSession.id_ciudad,
		id_colaborador: datosSession.id_colaborador,
		destino_transportadora: '',
		id_ubicacion: '',
	});

	const actualizarInput = (e) => {
		setDatosAdicionales({
			...datosAdicionales,
			[e.target.name]: e.target.value,
		});
	};

	const registrarDatosAdicionales = async () => {
		try {
			await createServicioTransporte(datosAdicionales, selectedOtgs);

			handleCambioEstado();
			setModalTransportadora(false);
		} catch (error) {
			if (error.response) {
				handleClickSnackbar(
					`Error al crear servicio de transporte. ${error.response.data.message}`,
					'error'
				);
			} else {
				handleClickSnackbar(error.message, 'error');
			}
		}
	};

	useEffect(() => {
		try {
			const fetchData = async () => {
				const _otgs = await fetch(query, {
					method: 'GET',
					headers: new Headers({
						'Content-Type': 'application/json',
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('colaborador')).token
						}`,
					}),
				})
					.then((response) => response.json())
					.then((data) => data);

				const groupedOtgs = Object.values(
					_.groupBy(_otgs, 'ubiOrigen.nombre_ubicacion')
				);

				setOtgs(groupedOtgs);
			};
			fetchData();
		} catch (error) {
			console.log(error);
		}
	}, [cambioEstadoExitoso, query]);

	useEffect(() => {
		try {
			async function getModalData() {
				const dataCiudades = await findCiudades();
				setCiudades(dataCiudades.data);
			}

			getModalData();
		} catch (error) {
			console.log(error);
		}
	}, []);
	useEffect(() => {
		if (datosAdicionales.destino_transportadora) {
			try {
				async function getModalData() {
					const dataTransportadoras = await findTransportadorasCiudad({
						id_ciudad: datosAdicionales.destino_transportadora,
					});

					setTransportadoras(dataTransportadoras);
				}

				getModalData();
			} catch (error) {
				console.log(error);
			}
		}
	}, [datosAdicionales.destino_transportadora]);

	const ModalAdicionales = (
		<Dialog
			onClose={() => {
				setModalTransportadora(false);
			}}
			open={modalTransportadora}
		>
			<DialogTitle align='center'>
				Ingrese los datos de transportadora
			</DialogTitle>
			<DialogContent>
				<TextField
					margin='dense'
					fullWidth
					name='numero_guia'
					label='Número guia'
					value={datosAdicionales.numero_guia}
					onChange={actualizarInput}
					type='text'
				/>

				<TextField
					margin='dense'
					fullWidth
					name='numero_caja'
					label='Número de cajas'
					value={datosAdicionales.numero_caja}
					onChange={actualizarInput}
					type='number'
				/>

				<TextField
					margin='dense'
					fullWidth
					name='costo'
					label='Costo'
					value={datosAdicionales.costo}
					onChange={actualizarInput}
					type='number'
				/>
				<TextField
					margin='dense'
					fullWidth
					name='a_nombre'
					label='A nombre'
					value={datosAdicionales.a_nombre}
					onChange={actualizarInput}
				/>

				<FormControl fullWidth margin='dense'>
					<InputLabel id='ciudad-destino'>Ciudad Destino</InputLabel>
					<Select
						labelId='ciudad-destino'
						label='Ciudad Destino'
						value={datosAdicionales.destino_transportadora}
						onChange={(e) => {
							setDatosAdicionales({
								...datosAdicionales,
								destino_transportadora: e.target.value,
								id_ubicacion: '',
							});
						}}
					>
						<MenuItem value='' disabled>
							Seleccione uno
						</MenuItem>

						{ciudades.map((ciudad) => {
							return (
								<MenuItem key={ciudad.id_ciudad} value={ciudad.id_ciudad}>
									{ciudad.nombre_ciudad}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>

				<FormControl fullWidth margin='dense'>
					<InputLabel id='transportadora'>Transportadora</InputLabel>
					<Select
						labelId='transportadora'
						label='Transportadora'
						value={datosAdicionales.id_ubicacion}
						onChange={(e) => {
							setDatosAdicionales({
								...datosAdicionales,
								id_ubicacion: e.target.value,
							});
						}}
					>
						<MenuItem value='' disabled>
							Seleccione uno
						</MenuItem>

						{transportadoras.map((transportadora) => {
							return (
								<MenuItem
									key={transportadora.id_ubicacion}
									value={transportadora.id_ubicacion}
								>
									{transportadora.nombre_ubicacion}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setModalTransportadora(false);
					}}
					variant='outlined'
					sx={{ backgroundColor: 'red', color: 'white' }}
				>
					Cancelar
				</Button>
				<Button onClick={registrarDatosAdicionales} variant='contained'>
					Registrar datos
				</Button>
			</DialogActions>
		</Dialog>
	);

	return (
		<>
			{ModalAdicionales}
			<RecibirDialog
				open={dialogOpen}
				onClose={handleCloseDialog}
				otgs={selectedOtgs}
				cambioEstadoExitoso={handleCambioEstado}
				nextState={nextState}
				confirmTitle={confirmTitle}
			></RecibirDialog>
			{otgs === null ? (
				<CircularProgress />
			) : (
				<AccordionCustom>
					<Grid container justifyContent='center'>
						<Grid container direction='row' justifyContent='space-between'>
							<Typography variant='h6'>{title}</Typography>

							<Typography variant='h6'>{_.flatMapDeep(otgs).length}</Typography>
						</Grid>

						<AccordionSummary expandIcon={<ExpandMore />}></AccordionSummary>
					</Grid>

					<AccordionDetails>
						<Grid container direction='column'>
							{otgs.map((ubicacion, i) => {
								return (
									<Grid key={i} container direction='column' sx={{ m: 1 }}>
										<Typography align='center' variant='h6'>
											{ubicacion[0].ubiOrigen.nombre_ubicacion}
										</Typography>

										<FormGroup>
											{ubicacion.map((otg) => {
												return (
													<Grid
														key={otg.id_otg}
														container
														justifyContent='space-around'
														sx={{ m: 1, border: '1px solid #f2f2f2' }}
													>
														<Typography
															sx={{ margin: 'auto 0' }}
														>{`OTG:  ${otg.id_otg}`}</Typography>

														<Typography
															sx={{ margin: 'auto 0' }}
														>{`Destino:  ${otg.ubiDestino.nombre_ubicacion}`}</Typography>

														<Checkbox
															color='primary'
															value={otg.id_otg}
															onChange={(e) => {
																addOrRemoveElement(
																	selectedOtgs,
																	e.target.value
																);
															}}
														/>
													</Grid>
												);
											})}
										</FormGroup>
									</Grid>
								);
							})}
							{_.flatMapDeep(otgs).length !== 0 ? (
								<Button
									onClick={() => {
										if (buttonText === 'Transportadora') {
											setModalTransportadora(true);
										} else {
											handleOpenDialog();
										}
									}}
									variant='contained'
									color='primary'
								>
									{buttonText}
								</Button>
							) : (
								<Typography variant='h6' align='center'>
									No hay OTGS para recibir de esta ubicación
								</Typography>
							)}
						</Grid>
					</AccordionDetails>
				</AccordionCustom>
			)}
		</>
	);
}
