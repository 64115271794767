import React from 'react';

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material';

export default function Alert(props) {
	const { title, message, open, SetModalEntregar } = props;

	const handleClose = () => {
		SetModalEntregar(false);
	};

	return (
		<Dialog open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
        <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button
            variant='outlined'
            onClick={handleClose}
            autoFocus
            sx={{
                backgroundColor: '#00a082',
                color: 'white',
                '&:hover': {
                    backgroundColor: '#ffffff',
					color: '#00a082',
                },
            }}
        >
            Aceptar
        </Button>
    </DialogActions>
</Dialog>

	);
}
