import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

export const crearServicioProveedor = async (datosServicioProveedor) => {
	return await axios.post(
		`${API_URL}crear_servicio_proveedor`,
		datosServicioProveedor,
		{
			headers: {
				authorization: 'Bearer ' + token,
			},
		}
	);
};
