import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

//! ES NECESARIO USAR ESTE SERVICIO
// export const crearServicio = (datosServicio) => {
// 	return axios.post(`${API_URL}servicios`, datosServicio).then((response) => {
// 		return response;
// 	});
// };

export const agregarCosto = (datosCosto) => {
	return axios
		.post(`${API_URL}agregar_costo`, datosCosto, {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const updateServicio = (datos) => {
	return axios.put(`${API_URL}update_servicio`, datos).then((response) => {
		return response;
	});
};

export const addCost = async (datos) => {
	const response = await axios.put(`${API_URL}add-cost`, datos);
	return response;
};

export const updateServicios = async (datos) => {
	return await axios
		.put(`${API_URL}update_servicios`, datos)
		.then((response) => {
			return response;
		});
};

export const otgServices = async (id_otg) => {
	return await axios.post(`${API_URL}service/otg-services`, {
		id_otg: id_otg,
	});
};
